import React from "react";
import _ from "lodash";
import classnames from "classnames";
import Card from "../Card/Card";
import styles from "./TablePlaceholder.module.scss";

const TablePlaceholder = ({ rows = 5, footer = false, ...rest }) => {
  return (
    <Card {...rest}>
      <div className={styles.tablePlaceholder}>
        <div
          className={classnames(
            "d-grid gap-3 border-bottom px-3 pb-3",
            styles.tableHeader
          )}
        >
          <div className={classnames("rounded-4", styles.tableHeaderItem)}></div>
          <div className={classnames("rounded-4", styles.tableHeaderItem)}></div>
          <div className={classnames("rounded-4", styles.tableHeaderItem)}></div>
          <div className={classnames("rounded-4", styles.tableHeaderItem)}></div>
          <div className={classnames("rounded-4", styles.tableHeaderItem)}></div>
        </div>
        <div className={styles.tableBody}>
          {_.range(rows).map((value) => (
            <div
              key={`ph-${value}`}
              className={classnames("d-grid gap-3 px-3 py-3", styles.tableRow, {
                [styles.lastRow]: !footer && value === rows - 1,
              })}
            >
              <div className={classnames("rounded-4", styles.tableCell)}></div>
              <div className={classnames("rounded-4", styles.tableCell)}></div>
              <div className={classnames("rounded-4", styles.tableCell)}></div>
              <div className={classnames("rounded-4", styles.tableCell)}></div>
              <div className={classnames("rounded-4", styles.tableCell)}></div>
            </div>
          ))}
          {footer ? <div className={styles.tableFooter}></div> : null}
        </div>
      </div>
    </Card>
  );
};

export default TablePlaceholder;

import React from "react";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import StatsBar from "../../../components/Stats/StatsBar.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { useFetch } from "../../../hooks";
import Loader from "../../../components/Loader/Loader.js";
import TitleWithTooltip from "../../../components/TitleWithTooltip/TitleWithTooltip.js";

function AssetAsCollateralInfo(props) {
  const { symbol } = props;
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/collateral/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const collateralStats = [
    {
      title: (
        <TitleWithTooltip
          title="Debt Collateralized"
          tooltipValue={`Total debt collateralized by ${symbol}.`}
        />
      ),
      bigValue: (
        <Value
          value={data.total}
          decimals={2}
          prefix="$"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <>
          <ValueChange
            value={0}
            decimals={2}
            suffix=""
            hideIfZero
            tooltipValue={0}
            className="text-small"
          />
        </>
      ),
    },
    {
      title: (
        <TitleWithTooltip
          title="Supply utilization "
          tooltipValue={`Percent of supply used as collateral.`}
        />
      ),
      normalValue: (
        <Value
          value={data.utilization}
          decimals={2}
          suffix="%"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <ValueChange
          value={0}
          decimals={2}
          suffix=""
          hideIfZero
          tooltipValue={0}
          className="text-small"
        />
      ),
    },
    {
      title: (
        <TitleWithTooltip
          title="Liquidation buffer "
          tooltipValue={`Debt that can be drawn untill liquidation threshold is reached.`}
        />
      ),
      bigValue: (
        <>
          <Value
            value={data.available}
            decimals={2}
            prefix="$"
            className="text-big"
            compact
          />
        </>
      ),
      smallValue: (
        <ValueChange
          value={0}
          decimals={2}
          suffix=""
          hideIfZero
          tooltipValue={0}
          className="text-small"
        />
      ),
    },
  ];
  return <StatsBar stats={collateralStats} />;
}

export default withErrorBoundary(AssetAsCollateralInfo);

import { withErrorBoundary } from "../../hoc.js";
import { usePageTitle } from "../../hooks.js";
import LeaderBoardTable from "./components/LeaderBoardTable.js";

function Airdrop(props) {
  usePageTitle("Airdrop");

  return (
    <>
      <div className="justify-content-center my-5 text-center">
        <h1 className="h2 m-0 p-0">Airdrop Leaderboard</h1>
      </div>

      <LeaderBoardTable />
    </>
  );
}

export default withErrorBoundary(Airdrop);

import React from "react";
import { Col, Row } from "reactstrap";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Loader from "../../../components/Loader/Loader.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import BootstrapTable from "react-bootstrap-table-next";
function Params(props) {
  const { data, isLoading, isError, ErrorFallbackComponent } =
    useFetch("markets/params/");

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  return (
    <>
      <Row className="mb-4">
        <Col>
          <BootstrapTable
            keyField="underlying_address"
            data={data.emode}
            columns={[
              {
                dataField: "name",
                text: "emode",
                sort: false,
                formatter: (cell) => (
                  <CryptoIcon className="me-2" name={cell} size="2rem" />
                ),
                classes: "table-icon-cell",
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "name",
                text: "",
                sort: false,
                formatter: (cell, row) => (
                  <>
                    {row.symbol}
                    <span className="text-small gray">{cell}</span>
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },

              {
                dataField: "ltv",
                text: "LTV",
                formatter: (cell) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "liquidation_threshold",
                text: "Liquidation Threshold",
                formatter: (cell) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "liquidation_bonus",
                text: "Liquidation Bonus",
                formatter: (cell) => (
                  <>
                    <Value
                      value={Number(cell) > 1 ? (cell - 1) * 100 : cell}
                      decimals={2}
                      suffix="%"
                      compact
                    />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
            ]}
            bordered={false}
          />
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(Params);

import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Header.module.scss";

const OrderSection = ({
  currentOrder,
  orderField,
  onOrderChange,
  onPageChange,
  children,
}) => {
  let currentOrderField = currentOrder;
  let isAscOrder = true;
  if (currentOrder.startsWith("-")) {
    currentOrderField = currentOrder.substring(1);
    isAscOrder = false;
  }

  const isActive = currentOrderField === orderField;

  const onChange = () => {
    let field = null;
    if (isActive) {
      field = orderField;
      if (!currentOrder.startsWith("-")) {
        field = `-${orderField}`;
      }
    } else {
      field = `-${orderField}`;
    }

    if (onOrderChange) {
      onOrderChange(field);
    }

    if (onPageChange) {
      onPageChange(1);
    }
  };

  return (
    <>
      <button className={styles.buttonStyle} onClick={onChange}>
        {children}
        <div className={`ms-2 ${isActive ? "primary" : " "}`}>
          {isActive && isAscOrder ? (
            <FontAwesomeIcon icon={faArrowUp} size="xs" />
          ) : (
            <FontAwesomeIcon icon={faArrowDown} size="xs" />
          )}
        </div>
      </button>
    </>
  );
};

const HeaderCell = ({
  align,
  currentOrder,
  orderField,
  onOrderChange,
  onPageChange,
  allowOrder = true,
  children,
}) => {
  return (
    <div
      className={`d-flex align-items-center text-muted text-uppercase justify-content-${
        align || "start"
      }`}
    >
      {allowOrder && orderField ? (
        <OrderSection
          currentOrder={currentOrder}
          orderField={orderField}
          onOrderChange={onOrderChange}
          onPageChange={onPageChange}
        >
          {children}
        </OrderSection>
      ) : (
        children
      )}
    </div>
  );
};

export default HeaderCell;

import PropTypes from "prop-types";
import classnames from "classnames";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";

function Info({ title, children, iconSmall = true }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faCircleQuestion}
        className={classnames("link gray ms-2", { small: iconSmall })}
        onClick={toggle}
      />
      <Offcanvas direction="end" isOpen={isOpen} toggle={toggle}>
        <OffcanvasHeader toggle={toggle}>{title}</OffcanvasHeader>
        <OffcanvasBody>{children}</OffcanvasBody>
      </Offcanvas>
    </>
  );
}

Info.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.any,
  small: PropTypes.bool,
};

export default Info;

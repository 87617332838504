import Loader from "../../components/Loader/Loader.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import Value from "../../components/Value/Value.js";
import { withErrorBoundary } from "../../hoc.js";
import { Row, Col, Button } from "reactstrap";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import Info from "../../components/Info/Info.js";
import { useFetch, usePageTitle } from "../../hooks.js";
import AtRiskPositionsTable from "./components/AtRiskPositionsTable.js";
import AggregatedAtRiskChart from "./components/AggregatedAtRiskChart.js";
import { useShowQueryParams } from "../../hooks.js";
import { useLocation, useNavigate } from "react-router-dom";
import { smartLocationPrefix } from "../../utils/url.js";

function AtRisk(props) {
  usePageTitle("Positions at Risk Simulation");
  const { queryParams, setQueryParams } = useShowQueryParams();
  const priceChange = queryParams.get("price_change") || "0";
  const switchChange = (newValue) => setQueryParams({ price_change: newValue });
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  const navigate = useNavigate();
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "at-risk/info/",
    {
      price_change: priceChange,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  let options = [
    { key: "-80", value: "-80%" },
    { key: "-50", value: "-50%" },
    { key: "-10", value: "-10%" },
    { key: "0", value: "-/+ 5%" },
    { key: "10", value: "+10%" },
    { key: "30", value: "+30%" },
    { key: "50", value: "+50%" },
  ];

  const totalStats = [
    {
      title: "Wallets at risk",
      normalValue: <Value value={data.wallets_count} decimals={0} />,
    },
    {
      title: "Potential liquidations",
      normalValue: <Value value={data.liquidations_count} decimals={0} />,
    },
    {
      title: "Collateral at risk",
      normalValue: (
        <Value value={data.total_collateral} decimals={2} prefix="$" compact />
      ),
    },
    {
      title: "Repayable debt",
      normalValue: <Value value={data.total_debt} decimals={2} prefix="$" compact />,
    },
  ];

  return (
    <>
      <Row className="d-flex align-items-center">
        <Col> </Col>
        <Col>
          <h1 className="h2 text-center">Positions at Risk Simulation</h1>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            color="primary"
            onClick={() => {
              navigate(`${prefix}liquidation-simulator`, { replace: true });
            }}
          >
            Advanced Simulation
          </Button>
        </Col>
      </Row>

      <div className="d-flex align-items-center justify-content-center">
        <TimeSwitch
          activeOption={priceChange}
          onChange={switchChange}
          options={options}
        />
        <Info iconSmall={false}>
          A price change of +/- x assumes a market-wide shift where all non-stablecoin
          assets increase or decrease simultaneously.
        </Info>
      </div>
      <p className="mb-5 text-center"></p>
      <StatsBar {...props.baseProps} stats={totalStats} className="mb-5" />

      {data.wallets_count > 0 ? (
        <>
          <Row className="mb-5">
            <Col lg={6}>
              <AggregatedAtRiskChart price_change={priceChange} type={"collateral"} />
            </Col>
            <Col lg={6}>
              <AggregatedAtRiskChart price_change={priceChange} type={"debt"} />
            </Col>
          </Row>
          <AtRiskPositionsTable price_change={priceChange} />
        </>
      ) : (
        <div className="text-center mb-5 text-white">
          There are no wallets at risk of being liquidated at {priceChange}% price{" "}
          {priceChange >= 0 ? "increase" : "decrease"}.
        </div>
      )}
    </>
  );
}

export default withErrorBoundary(AtRisk);

import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import { withErrorBoundary } from "../../hoc.js";
import { usePageTitle } from "../../hooks";
import MarketsTable from "../homepage/components/MarketsTable.js";
import MarketCompositionChart from "./components/MarketCompositionChart.js";
import { useShowQueryParams } from "../../hooks";
import { Col, Row } from "reactstrap";
import Title from "../../components/Title/Title.js";
function Markets(props) {
  usePageTitle("Markets");
  const { queryParams, setQueryParams } = useShowQueryParams();
  const timePeriod = queryParams.get("days_ago") || "1";
  const switchChange = (newValue) => setQueryParams({ days_ago: newValue });

  const options = [
    { key: "1", value: "1 D" },
    { key: "7", value: "7 D" },
    { key: "30", value: "30 D" },
    { key: "90", value: "90 D" },
    { key: "365", value: "1 Y" },
  ];

  return (
    <>
      <div className="justify-content-center my-5 text-center">
        <h1 className="h2 m-0 p-0">Markets</h1>
      </div>

      <TimeSwitch
        activeOption={timePeriod}
        label={""}
        onChange={switchChange}
        options={options}
        className="justify-content-center mb-5"
      />

      <MarketsTable daysAgo={timePeriod} />
      <Title className={"mb-5"} tag="h2">
        Market Composition
      </Title>
      <Row className="mb-5">
        <Col xs="12" md="6" className="mb-5">
          <MarketCompositionChart type="supply" />
        </Col>
        <Col xs="12" md="6" className="mb-5">
          <MarketCompositionChart type="borrow" />
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(Markets);

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import ExternalLink from "../../../components/ExternalLink/ExternalLink.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { parseUTCDateTime } from "../../../utils/datetime.js";
import { useLocation } from "react-router-dom";
import { smartEtherscanUrl } from "../../../utils/url.js";
function MarketsActivityTable(props) {
  const { symbol } = props;
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-datetime");
  const location = useLocation();
  const etherscanUrl = smartEtherscanUrl(location);
  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/events/pool/`,
    {
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  return (
    <>
      <RemoteTable
        loading={isPreviousData}
        hover={false}
        keyField="order_index"
        data={data.results}
        defaultSorted={[
          {
            dataField: "datetime",
            order: "desc",
          },
        ]}
        columns={[
          {
            dataField: "symbol",
            text: "Token",
            formatter: (cell, row) => {
              if (row.event === "LiquidationCall") {
                return (
                  <span>
                    <CryptoIcon name={row.debt_symbol} size="2em" />

                    <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
                    <CryptoIcon name={row.symbol} size="2em" />
                  </span>
                );
              } else {
                return (
                  <>
                    <span>
                      <CryptoIcon name={symbol} size="2em" className="me-4" />
                      {symbol}
                    </span>
                  </>
                );
              }
            },
            headerAlign: "left",
            align: "left",
          },
          {
            dataField: "event",
            text: "Event",
            headerAlign: "left",
            align: "left",
          },

          {
            dataField: "amount",
            text: "Amount",
            formatter: (cell, row) => (
              <ValueChange
                value={cell}
                decimals={2}
                compact
                className="me-2"
                suffix={symbol}
              />
            ),
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "amount_usd",
            text: "$ amount",
            sort: true,
            formatter: (cell, row) => (
              <ValueChange
                value={cell}
                decimals={2}
                prefix="$"
                compact
                className="me-2"
              />
            ),
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "block_number",
            text: "Date",
            sort: true,
            formatter: (cell, row) => (
              <>
                <DateTimeAgo dateTime={parseUTCDateTime(row.datetime)} />
                <br />
                <span className="gray text-small">
                  {cell}
                  <ExternalLink
                    href={`${etherscanUrl}tx/${row.tx_hash}`}
                    className="ms-2 gray"
                  />
                </span>
              </>
            ),

            headerAlign: "right",
            align: "right",
          },
        ]}
        page={page}
        pageSize={pageSize}
        totalPageSize={data.count}
        onPageChange={setPage}
        onSortChange={setOrder}
      />
    </>
  );
}

export default withErrorBoundary(MarketsActivityTable);

import { withErrorBoundary } from "../../hoc.js";
import { usePageTitle } from "../../hooks.js";
import MarketParamsTable from "./components/MarketParamsTable.js";
import EmodeParamsTable from "./components/EmodeParamsTable.js";
import PoolConfigurationEventsTable from "./components/PoolConfigurationEventsTable.js";
import Title from "../../components/Title/Title.js";
function Params(props) {
  usePageTitle("Params");

  return (
    <>
      <div className="justify-content-center my-5 text-center">
        <h1 className="h2 m-0 p-0">Parameters</h1>
      </div>

      <Title className={"mb-5"} tag="h2">
        Market
      </Title>

      <MarketParamsTable />

      <Title className={"mb-5"} tag="h2">
        Emode
      </Title>
      <EmodeParamsTable />

      <Title className={"mb-5"} tag="h2">
        Parameter Changes
      </Title>
      <PoolConfigurationEventsTable />
    </>
  );
}

export default withErrorBoundary(Params);

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import makeBlockie from "ethereum-blockies-base64";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Address from "../../components/Address/Address.js";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../components/DateTime/DateTimeAgo.js";
import ExternalLink from "../../components/ExternalLink/ExternalLink.js";
import Loader from "../../components/Loader/Loader.js";
import RemoteTable from "../../components/Table/RemoteTable.js";
import ValueChange from "../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks.js";
import { parseUTCDateTime } from "../../utils/datetime.js";
import { useLocation } from "react-router-dom";
import { smartLocationPrefix, smartEtherscanUrl } from "../../utils/url.js";
function Activity(props) {
  usePageTitle("Activity");
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-datetime");
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  const etherscanUrl = smartEtherscanUrl(location);

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `activity/`,
    {
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results } = data;

  return (
    <>
      <h1 className="mb-4 h2 text-center">Activity</h1>
      <RemoteTable
        loading={isPreviousData}
        hover={false}
        keyField="order_index"
        data={results}
        defaultSorted={[
          {
            dataField: "datetime",
            order: "desc",
          },
        ]}
        columns={[
          {
            dataField: "",
            text: "",
            formatter: (_, row) => {
              const blockie = makeBlockie(row.wallet_address);
              return <img src={blockie} alt={row.wallet_address} />;
            },
            classes: "blockie",
          },
          {
            dataField: "wallet_address",
            text: "Wallet",
            formatter: (cell) => (
              <Link to={`${prefix}wallets/${cell}/`} key={cell}>
                <Address value={cell} short />
              </Link>
            ),
            headerAlign: "left",
            align: "left",
          },
          {
            dataField: "symbol",
            text: "asset",
            formatter: (cell, row) => {
              if (row.event === "LiquidationCall") {
                return (
                  <span>
                    <CryptoIcon name={row.debt_symbol} size="2em" />
                    <span className="mx-2">{row.debt_symbol}</span>
                    <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
                    <CryptoIcon name={row.symbol} size="2em" />
                    <span className="mx-2">{cell}</span>
                  </span>
                );
              } else {
                return (
                  <>
                    <span>
                      <CryptoIcon name={cell} size="2em" className="me-4" />
                      {cell}
                    </span>
                  </>
                );
              }
            },
            headerAlign: "left",
            align: "left",
          },

          {
            dataField: "event",
            text: "Event",
            sort: true,
            headerAlign: "left",
            align: "left",
          },
          {
            dataField: "amount_usd",
            text: "amount",
            sort: true,
            formatter: (cell, row) => (
              <>
                <ValueChange
                  value={row.amount}
                  decimals={2}
                  suffix={row.symbol}
                  compact
                  short={true}
                />
                <br />
                <ValueChange
                  className="text-small"
                  value={cell}
                  decimals={2}
                  tooltipValue={cell}
                  prefix="$"
                  compact
                  small
                  short={true}
                />
              </>
            ),
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "block_number",
            text: "Date",
            sort: true,
            formatter: (cell, row) => (
              <>
                <DateTimeAgo dateTime={parseUTCDateTime(row.datetime)} />
                <br />
                <span className="gray text-small">
                  {cell}
                  <ExternalLink
                    href={`${etherscanUrl}tx/${row.tx_hash}`}
                    className="ms-2 gray"
                  />
                </span>
              </>
            ),
            headerAlign: "right",
            align: "right",
          },
        ]}
        page={page}
        pageSize={pageSize}
        totalPageSize={data.count}
        onPageChange={setPage}
        onSortChange={setOrder}
      />
    </>
  );
}

export default withErrorBoundary(Activity);

import axios from "axios";
import queryString from "query-string";
import { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorFallback from "./components/errorFallback/ErrorFallback.js";
import { smartLocationPrefix } from "./utils/url.js";

export const useFetch = (path, query, options) => {
  let qs = queryString.stringify(query, { skipNull: true });
  if (qs) {
    qs = `?${qs}`;
  }

  let url = path;
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  url = `${prefix}${path}`;

  const settings = {
    retry: (failureCount, error) => {
      // Don't retry on 404 errors
      if (error.response && error.response.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    ...options,
  };

  const response = useQuery(
    [url, query],
    async () => {
      const { data } = await axios.get(`${url}${qs}`);
      return data;
    },
    settings
  );
  // Potential todo:
  // -> dispatch and action
  // -> manipulate the response before sending back to component
  // -> whatever makes sense for you to put here...
  return {
    ...response,
    ErrorFallbackComponent: ErrorFallback,
  };
};

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) return func();
    else didMount.current = true;
  }, deps); // eslint-disable-line
};

export const usePageTitle = (title) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title + " | Block Analitica";
    return () => {
      document.title = prevTitle;
    };
  });
};

export const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const useSmartNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const prefix = smartLocationPrefix(location);

  const smartNavigate = (e, path) => {
    e.preventDefault();

    const actualPath = prefix + path;
    if (e.ctrlKey || e.metaKey) {
      window.open(actualPath, "_blank");
    } else {
      navigate(actualPath);
    }
  };

  return smartNavigate;
};

export const useShowQueryParams = (defaultParams = {}) => {
  const location = useLocation();
  const navigate = useNavigate();

  let urlSearchParams = new URLSearchParams(location.search);

  if (urlSearchParams.toString() === "") {
    Object.entries(defaultParams).forEach(([key, value]) => {
      urlSearchParams.set(key, String(value));
    });
  }

  const setQueryParams = (params, replace = false) => {
    if (replace) {
      urlSearchParams = new URLSearchParams();
    }

    Object.entries(params).forEach(([key, value]) => {
      if (value === null) {
        urlSearchParams.delete(key);
      } else {
        urlSearchParams.set(key, String(value));
      }
    });

    const search = urlSearchParams.toString();
    const query = search ? `?${search}` : "";

    navigate(`${location.pathname}${query}`, { replace: true });
  };

  return { queryParams: urlSearchParams, setQueryParams };
};
export const useMediaQuery = (size, type = "min-width") => {
  const breakpoints = {
    xxs: "0px",
    xs: "320px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
  };

  const mediaQuery = `(${type}: ${breakpoints[size]})`;

  const mediaMatch = window.matchMedia(mediaQuery);
  const [match, setMatch] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatch(e.matches);
    mediaMatch.addEventListener("change", handler);
    return () => mediaMatch.removeEventListener("change", handler);
  }, [mediaMatch]);

  return match;
};

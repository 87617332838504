import React from "react";
import StatsBar from "../../../components/Stats/StatsBar.js";
import Value from "../../../components/Value/Value.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";

function MarketInfo(props) {
  const { data } = props;
  if (!data) {
    return null;
  }

  const { change = {} } = data;

  const totalStats = [
    {
      title: "Supplied",
      normalValue: (
        <>
          <Value
            value={data.total_supply_usd}
            decimals={2}
            prefix={"$"}
            compact
            className="me-2 gray"
          />
          <ValueChange
            value={data.total_supply_usd - change.total_supply_usd}
            decimals={2}
            prefix={"$"}
            compact
            hideIfZero
            tooltipValue={change.total_supply_usd}
          />
        </>
      ),
      bigValue: (
        <>
          <Value
            value={data.total_supply}
            decimals={2}
            suffix={data.symbol}
            className="me-2"
            compact
            big
          />
          <ValueChange
            value={data.total_supply - change.total_supply}
            decimals={2}
            tooltipValue={change.total_supply}
            compact
            hideIfZero
            big
          />
        </>
      ),
    },
    {
      title: "Borrowed",
      normalValue: (
        <>
          <Value
            value={data.total_borrow_usd}
            decimals={2}
            prefix={"$"}
            compact
            className="me-2 gray"
          />
          <ValueChange
            value={data.total_borrow_usd - change.total_borrow_usd}
            decimals={2}
            compact
            prefix={"$"}
            hideIfZero
            tooltipValue={change.total_borrow_usd}
          />
        </>
      ),
      bigValue: (
        <>
          <Value
            value={data.total_borrow}
            decimals={2}
            suffix={data.symbol}
            className="me-2"
            compact
            big
          />
          <ValueChange
            value={data.total_borrow - change.total_borrow}
            decimals={2}
            tooltipValue={change.total_borrow}
            compact
            hideIfZero
            big
          />
        </>
      ),
    },
    {
      title: "available liquidity",
      normalValue: (
        <>
          <Value
            value={data.tvl_usd}
            decimals={2}
            prefix={"$"}
            className="me-2 gray"
            compact
          />
          <ValueChange
            value={data.tvl_usd - change.tvl_usd}
            decimals={2}
            tooltipValue={change.tvl_usd}
            prefix={"$"}
            compact
            hideIfZero
          />
        </>
      ),
      bigValue: (
        <>
          <Value
            value={data.tvl}
            decimals={2}
            suffix={data.symbol}
            compact
            big
            className="me-2"
          />
          <ValueChange
            value={data.tvl - change.tvl}
            decimals={2}
            tooltipValue={change.tvl}
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "oracle Price",
      bigValue: (
        <>
          <Value
            value={data.underlying_price}
            decimals={2}
            prefix={"$"}
            className="me-2"
            compact
          />

          <ValueChange
            value={data.underlying_price - change.price}
            prefix={"$"}
            decimals={2}
            tooltipValue={change.price}
            compact
            hideIfZero
          />
        </>
      ),
    },
  ];

  return <StatsBar stats={totalStats} />;
}

export default withErrorBoundary(MarketInfo);

import { Col, Row } from "reactstrap";
import TimeSwitch from "../../../components/TimeSwitch/TimeSwitch.js";
import Title from "../../../components/Title/Title.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import CollateralAtRiskChart from "./CollateralAtRiskChart.js";
import AtRiskHistoryChart from "./AtRiskHistoryChart.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { useShowQueryParams } from "../../../hooks.js";

function CollateralAtRiskSection(props) {
  const { queryParams, setQueryParams } = useShowQueryParams();
  const positionsType = queryParams.get("positionsType") || "long";
  const switchChange = (newValue) => setQueryParams({ positionsType: newValue });
  const { data, isLoading, isError } = useFetch("markets/at-risk/", {
    type: positionsType,
  });

  const results = data ? data.results : null;

  return (
    <div>
      <Title className={"mb-5"} tag="h2">
        Collateral at Risk
      </Title>
      <TimeSwitch
        className="mb-5 justify-content-center"
        activeOption={positionsType}
        label={""}
        onChange={switchChange}
        options={[
          { key: "long", value: "price decrease" },
          { key: "short", value: "price increase" },
        ]}
      />
      {results === null || results.length === 0 ? (
        <div className="text-center mb-5 text-white">
          No collateral at risk in case of price{" "}
          {positionsType >= "short" ? "increase" : "decrease"}.
        </div>
      ) : (
        <>
          <Row className="mb-5">
            <Col xs="12" md="6" className="subtitle">
              <LoadingCard
                title="Cumulative per drop"
                isLoading={isLoading}
                isError={isError}
                data={results}
              >
                <CollateralAtRiskChart
                  data={results}
                  type={positionsType}
                  chartType={"line"}
                />
              </LoadingCard>
            </Col>
            <Col xs="12" md="6" className="subtitle">
              <LoadingCard
                title="per drop"
                isLoading={isLoading}
                isError={isError}
                data={results}
              >
                <CollateralAtRiskChart
                  data={results}
                  type={positionsType}
                  chartType={"bar"}
                />
              </LoadingCard>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md="12">
              <LoadingCard
                title="cumulative per drop - history"
                isLoading={isLoading}
                isError={isError}
                data={results}
              >
                <AtRiskHistoryChart type={positionsType} />
              </LoadingCard>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default withErrorBoundary(CollateralAtRiskSection);

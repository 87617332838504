import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { SYMBOLS_PALETTE } from "../../../utils/colors.js";
import {
  barGraphSeriesCountLimiter,
  tooltipLabelNumberWithPercent,
} from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
function LiquidationsCollateralDebtChart(props) {
  const { timePeriod } = props;
  const { data, isLoading, isError } = useFetch("liquidations/collateral-debt/", {
    type: "debt",
    days_ago: timePeriod,
  });

  const { series } = barGraphSeriesCountLimiter(data, "key", "value", 7, true);
  const backgroundColor = series[0]["data"].map((row) => {
    return SYMBOLS_PALETTE[row["x"]] || "#0e1726";
  });
  const borderColors = series[0]["data"].map((row) => {
    return SYMBOLS_PALETTE[row["x"]] || "#0e1726";
  });

  const newSeries = [
    {
      data: series[0]["data"],
      backgroundColor,
      borderColor: borderColors,
    },
  ];

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      y: {
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: (tooltipItem) => {
          return tooltipItem.parsed.y !== 0 && tooltipItem.parsed.y !== null;
        },
        callbacks: {
          label: (tooltipItem) => {
            return tooltipLabelNumberWithPercent(tooltipItem, "$");
          },
        },
      },
    },
  };

  return (
    <>
      <LoadingCard
        minHeight
        title={"debt repaid"}
        data={data}
        isLoading={isLoading}
        isError={isError}
      >
        <Graph series={newSeries} options={options} type="bar" />
      </LoadingCard>
    </>
  );
}

export default withErrorBoundary(LiquidationsCollateralDebtChart);

import { withErrorBoundary } from "../../../hoc.js";
import { useParams } from "react-router-dom";
import AssetAsCollateralInfo from "./AssetAsCollateralInfo.js";
import AssetAsCollateralSankey from "./AssetAsCollateralSankey.js";
import AssetAsCollateralHistory from "./AssetAsCollateralHistory.js";
import AssetAsCollateralUtilization from "./AssetAsCollateralUtilization.js";
import AssetAsCollateralComposition from "./AssetAsCollateralComposition.js";
import React, { useState } from "react";
import { useFetch } from "../../../hooks";
import Loader from "../../../components/Loader/Loader.js";
import TimeSwitch from "../../../components/TimeSwitch/TimeSwitch.js";
import { Col, Row } from "reactstrap";
function AssetAsCollateral(props) {
  const { symbol } = useParams();
  const [querySymbol, setQuerySymbol] = useState(symbol);
  const [activeOption, setActiveOption] = useState(false);
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/collateral-is-emode/`
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }
  const isEmode = data;

  const switchChange = (option) => {
    if (option === true) {
      setQuerySymbol(`${symbol}e`);
      setActiveOption(true);
    } else {
      setQuerySymbol(symbol);
      setActiveOption(false);
    }
  };
  const emodeOptions = [
    { key: false, value: "standard" },
    { key: true, value: "e-mode" },
  ];

  return (
    <>
      {isEmode ? (
        <TimeSwitch
          className="mb-5 justify-content-center"
          activeOption={activeOption}
          onChange={switchChange}
          options={emodeOptions}
        />
      ) : (
        <></>
      )}

      <Row className="justify-content-center mb-5">
        <AssetAsCollateralInfo symbol={querySymbol} />
      </Row>
      <Row className="justify-content-center mb-5">
        <Col md={6}>
          <AssetAsCollateralSankey symbol={querySymbol} />
        </Col>
        <Col md={6}>
          <AssetAsCollateralComposition symbol={querySymbol} />
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col md={6}>
          <AssetAsCollateralHistory symbol={querySymbol} />
        </Col>

        <Col md={6}>
          <AssetAsCollateralUtilization symbol={querySymbol} />
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(AssetAsCollateral);

import React from "react";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import LinkTable from "../../../components/Table/LinkTable.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useSmartNavigate } from "../../../hooks.js";

function WalletPositionsBorrow(props) {
  const { data } = props;
  const navigate = useSmartNavigate();

  const onRowClick = (e, row) => {
    navigate(e, `markets/${row.underlying_symbol}/`);
  };

  const columns = [
    {
      formatter: (cell, row) => (
        <CryptoIcon name={row.underlying_symbol} size="2em" id={cell} />
      ),
      classes: "table-icon-cell",
    },
    {
      dataField: "underlying_symbol",
      text: "",
      formatter: (cell, row) => (
        <>
          {cell}
          <div className="lh-sm">
            <Value value={row.price} decimals={2} prefix="$" className="gray" small />
          </div>
        </>
      ),
    },
    {
      dataField: "borrow",
      text: "",
      formatter: (cell, row) => (
        <>
          <Value value={cell} decimals={2} suffix={row.underlying_symbol} compact />
          <div className="lh-sm">
            <Value
              className="gray"
              value={row.borrow_usd}
              prefix="$"
              decimals={2}
              compact
              small
            />
          </div>
        </>
      ),
      headerAlign: "right",
      align: "right",
    },
  ];

  return (
    <>
      <h3 className="text-center subtitle mb-2">borrowed</h3>
      {data.length === 0 ? (
        <div className="text-center gray">no borrow positions</div>
      ) : (
        <LinkTable
          keyField="underlying_symbol"
          data={data}
          onRowClick={onRowClick}
          defaultSorted={[
            {
              dataField: "borrow_usd",
              order: "desc",
            },
          ]}
          columns={columns}
        />
      )}
    </>
  );
}

export default withErrorBoundary(WalletPositionsBorrow);

import classnames from "classnames";
import _ from "lodash";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../pagination/Pagination";
import { Link } from "react-router-dom";
import HeaderCell from "./HeaderCell";
import TablePlaceholder from "./TablePlaceholder";
import { useMediaQuery } from "../../hooks";
import Card from "../Card/Card";
import styles from "./Table.module.scss";
const Table = ({
  className,
  data = [],
  keyField,
  columns,
  href,
  currentPage,
  pageSize,
  currentOrder,
  totalRecords,
  onPageChange,
  onOrderChange,
  allowOrder,
  footerRow,
  emptyIcon,
  emptyTitle,
  headerAlign,
  isLoading = false,
  placeholderRows,
  placeholderFooter,
  sortOnClient,
  ...rest
}) => {
  const media = {
    xxs: useMediaQuery("xxs"),
    xs: useMediaQuery("xs"),
    sm: useMediaQuery("sm"),
    md: useMediaQuery("md"),
    lg: useMediaQuery("lg"),
    xl: useMediaQuery("xl"),
    xxl: useMediaQuery("xxl"),
  };

  if (isLoading) {
    return (
      <TablePlaceholder
        className={className}
        rows={placeholderRows}
        footer={placeholderFooter}
      />
    );
  }

  let RowComponent = "div";
  if (href) {
    RowComponent = Link;
  }

  const totalPages = Math.ceil(totalRecords / pageSize);

  const cellSizes = columns
    .map((column) => {
      if (column.visibleAfter && !media[column.visibleAfter]) {
        return null;
      }
      return column.cellSize || "1fr";
    })
    .join(" ");

  const justifyMapping = {
    start: "justify-content-start",
    center: "justify-content-center",
    end: "justify-content-end",
  };

  const itemsMapping = {
    start: "align-items-start",
    center: "align-items-center",
    end: "align-items-end",
  };

  return (
    <div className={className}>
      <Card {...rest}>
        <div
          className={"d-grid gap-3 border-bottom text-small px-3 pb-3"}
          style={{ gridTemplateColumns: cellSizes }}
        >
          {columns.map((column, colIndex) => {
            const isVisible = column.visibleAfter ? media[column.visibleAfter] : true;
            if (!isVisible) {
              return null;
            }
            return (
              <HeaderCell
                key={column.header || `col-${colIndex}`}
                align={column.headerAlign}
                orderField={column.orderField}
                currentOrder={currentOrder}
                onOrderChange={onOrderChange}
                onPageChange={onPageChange}
                allowOrder={allowOrder}
              >
                {column.header}
              </HeaderCell>
            );
          })}
        </div>

        <div className="mx-2 d">
          {data.length > 0 ? (
            data.map((row, index) => (
              <RowComponent
                key={row[keyField]}
                className={classnames(styles.table, {
                  [styles.noBorderBottom]:
                    (totalRecords
                      ? index === data.results.length - 1
                      : index === data.length - 1) && !footerRow,
                })}
                style={{ gridTemplateColumns: cellSizes }}
                to={_.isFunction(href) ? href(row) : href}
                prefetch={false}
              >
                {columns.map((column, colIndex) => {
                  const isVisible = column.visibleAfter
                    ? media[column.visibleAfter]
                    : true;
                  if (!isVisible) {
                    return null;
                  }
                  return (
                    <div
                      key={`row-${row[keyField]}-${colIndex}`}
                      className={classnames(
                        "d-flex gap-3 align-items-center py-2 px-2",
                        justifyMapping[column.cellAlign || "start"]
                      )}
                    >
                      <div
                        className={classnames(
                          "d-flex text-body flex-column",
                          itemsMapping[column.cellAlign || "start"]
                        )}
                      >
                        <div>{column["cell"]({ row, index })}</div>
                        <div className="d-flex text-small">
                          {column["smallCell"]
                            ? column["smallCell"]({ row, index })
                            : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </RowComponent>
            ))
          ) : (
            <div
              className={classnames(
                styles.centerScreen,
                "d-flex flex-column align-items-center justify-content-center"
              )}
            >
              <div className={styles.roundedCircle}>
                <FontAwesomeIcon
                  icon={emptyIcon ? emptyIcon : faFolderOpen}
                  size="xl"
                />
              </div>

              <div className="mt-3 gray">{emptyTitle ? emptyTitle : "No Items"}</div>
            </div>
          )}
        </div>
        {footerRow && data.length > 0 ? (
          <div className="px-6 pt-3 text-small">{footerRow}</div>
        ) : null}
      </Card>
      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          className="mt-6"
        />
      ) : null}
    </div>
  );
};

export default Table;

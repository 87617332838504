import React, { useState } from "react";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Title from "../../../components/Title/Title.js";
import Value from "../../../components/Value/Value.js";
import { useFetch } from "../../../hooks";
import { parseUTCDateTime } from "../../../utils/datetime.js";

function WalletAtRiskPositions(props) {
  const { address } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-collateral_amount_usd");

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `wallets/${address}/at-risk/`,
    {
      address,
      p: page,
      p_size: 5,
      order,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  let priceField;
  let priceFieldText;
  if (data.results[0]?.price_change < 0) {
    priceField = "collateral_price";
    priceFieldText = "At collateral price";
  } else {
    priceField = "debt_price";
    priceFieldText = "At debt price";
  }

  return (
    <>
      {data.results.length > 0 ? (
        <>
          <Title tag="h2">Liquidation simulations</Title>

          <RemoteTable
            loading={isPreviousData}
            keyField="id"
            hover={false}
            data={data.results}
            columns={[
              {
                dataField: "collateral_amount_usd",
                text: "collateral liquidated",
                sort: true,
                formatter: (cell, row) => (
                  <>
                    <div className="d-flex align-items-center">
                      <CryptoIcon
                        name={row.collateral_symbol}
                        size="2em"
                        className="me-3"
                      />
                      <div>
                        <Value
                          value={row.collateral_amount}
                          decimals={2}
                          suffix={row.collateral_symbol}
                          compact
                        />
                        <br />
                        <Value
                          value={cell}
                          decimals={2}
                          className="text-small gray"
                          prefix="$"
                          compact
                        />
                      </div>
                    </div>
                  </>
                ),
              },
              {
                dataField: "debt_amount_usd",
                text: "Debt Repaid",
                headerAlign: "left",
                align: "left",
                sort: true,
                formatter: (cell, row) => (
                  <>
                    <div className="d-flex align-items-center">
                      <CryptoIcon name={row.debt_symbol} size="2em" className="me-3" />
                      <div>
                        <Value
                          value={row.debt_amount}
                          decimals={2}
                          suffix={row.debt_symbol}
                          compact
                        />
                        <br />
                        <Value
                          value={cell}
                          decimals={2}
                          className="text-small gray"
                          prefix="$"
                          compact
                        />
                      </div>
                    </div>
                  </>
                ),
              },
              {
                dataField: priceField,
                text: priceFieldText,
                sort: false,
                formatter: (cell, row) => (
                  <Value value={cell} decimals={2} prefix="$" compact100k />
                ),
                headerAlign: "left",
                align: "left",
              },

              {
                dataField: "price_change",
                text: `Price change`,
                sort: true,
                formatter: (cell) => <Value value={cell} decimals={0} suffix="%" />,
                headerAlign: "left",
                align: "left",
              },
              {
                dataField: "incentive",
                text: "incentive",
                sort: true,
                formatter: (cell, row) => (
                  <Value value={cell} decimals={2} prefix="$" compact100k />
                ),
                headerAlign: "left",
                align: "left",
              },
              {
                dataField: "last_activity",
                text: "last activity",
                sort: false,
                formatter: (cell, row) => (
                  <DateTimeAgo dateTime={parseUTCDateTime(cell)} inDays />
                ),
                headerAlign: "left",
                align: "left",
              },
            ]}
            page={page}
            pageSize={5}
            totalPageSize={data.count}
            onPageChange={setPage}
            onSortChange={setOrder}
          />
        </>
      ) : null}
    </>
  );
}

export default WalletAtRiskPositions;

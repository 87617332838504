import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import Loader from "../../../components/Loader/Loader.js";
import TitleWithTooltip from "../../../components/TitleWithTooltip/TitleWithTooltip.js";

function AssetAsCollateralUtilization(props) {
  const { symbol } = props;
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/collateral-utilization-history/`
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  } else if (data.length === 0) {
    return <></>;
  }

  const collateral_utilization = [];

  if (data.length > 0) {
    data.forEach((row) => {
      collateral_utilization.push({
        x: row.date,
        y: row.collateral_utilization,
      });
    });
  }
  const title = (
    <TitleWithTooltip
      title="Collateral Utilization"
      tooltipValue={`Current debt divided by maximum debt currently collateralized.`}
    />
  );
  const series = [
    {
      label: "collateral utilization",
      data: collateral_utilization,
    },
  ];

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        min: 0,
        max: 100,
        stacked: false,
        ticks: {
          callback: (value) => compact(value, 2, true) + "%",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            const value = tooltipItem.parsed.y;
            return value !== null ? value.toFixed(2) + "%" : "";
          },
        },
      },
    },
  };

  return (
    <LoadingCard
      title={title}
      minHeigh
      isError={isError}
      isLoading={isLoading}
      data={data}
    >
      <Graph series={series} options={options} />
    </LoadingCard>
  );
}

export default withErrorBoundary(AssetAsCollateralUtilization);

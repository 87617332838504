import { useParams, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import ExternalLink from "../../components/ExternalLink/ExternalLink.js";
import Loader from "../../components/Loader/Loader.js";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import SegmentSwitch from "../../components/SegmentSwitch/SegmentSwitch.js";
import Title from "../../components/Title/Title.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks";
import CollateralAtRiskSection from "./components/CollateralAtRiskSection.js";
import HistoricDetails from "./components/HistoricDetails";
import InfoCard from "./components/InfoCard.js";
import InfoCardApy from "./components/InfoCardApy.js";
import MarketInfo from "./components/MarketInfo.js";
import MarketActivityTable from "./components/MarketsActivityTable.js";
import RateHistoryChart from "./components/RateHistoryChart.js";
import UtilizationChart from "./components/UtilizationChart.js";
import WalletPositions from "./components/WalletPositions.js";
import AssetAsCollateral from "./components/AssetAsCollateral.js";
import AssetAsDebt from "./components/AssetAsDebt.js";
import { useShowQueryParams } from "../../hooks";
import { smartLocationPrefix, smartEtherscanUrl } from "../../utils/url.js";

function Market(props) {
  const { symbol, segment } = useParams();
  usePageTitle(symbol);

  const { queryParams, setQueryParams } = useShowQueryParams();
  const timePeriod = queryParams.get("days_ago") || "90";
  const switchChange = (newValue) => setQueryParams({ days_ago: newValue });
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  const options = [
    { key: "1", value: "1 D" },
    { key: "7", value: "7 D" },
    { key: "30", value: "30 D" },
    { key: "90", value: "90 D" },
    { key: "365", value: "1 Y" },
  ];

  const segmentOptions = [
    { key: "", value: "Market" },
    { key: "risk", value: "Risk" },
    { key: "wallets", value: "Wallets" },
    { key: "activity", value: "Activity" },
    { key: "collateral", value: "Collateral" },
    { key: "debt", value: "Debt" },
  ];

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/`,
    { days_ago: timePeriod }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }
  const etherscanUrl = smartEtherscanUrl(location);

  const {
    symbol: underlyingSymbol,
    underlying_address: underlyingAddress,
    underlying_name: name,
  } = data;

  const createUrl = (params, optionKey) => {
    return `${prefix}markets/${symbol}/${optionKey}`;
  };

  function renderSegment(segment) {
    switch (segment) {
      case "risk":
        return (
          <>
            <Title className="mb-5" tag="h2">
              Collateral at Risk
            </Title>
            <div className="mb-5">
              <InfoCard data={data} />
            </div>

            <CollateralAtRiskSection symbol={underlyingSymbol} />
          </>
        );
      case "wallets":
        return (
          <>
            <Title className="mb-5" tag="h2">
              Wallets
            </Title>

            <WalletPositions symbol={underlyingSymbol} />
          </>
        );
      case "activity":
        return (
          <>
            <Title className="mb-5" tag="h2">
              Activity
            </Title>
            <MarketActivityTable symbol={symbol} prefix={prefix} />
          </>
        );
      case "collateral":
        return (
          <>
            <Title className="mb-5" tag="h2">
              Collateral
            </Title>

            <AssetAsCollateral symbol={symbol} />
          </>
        );
      case "debt":
        return (
          <>
            <Title className="mb-5" tag="h2">
              Debt
            </Title>

            <AssetAsDebt symbol={symbol} />
          </>
        );
      default:
        return (
          <>
            <Title className="mb-5" tag="h2">
              Market
            </Title>
            <div className="mb-5">
              <TimeSwitch
                activeOption={timePeriod}
                onChange={switchChange}
                options={options}
                className="justify-content-center mb-5"
              />
              <MarketInfo data={data} />
            </div>
            <Row className="justify-content-center mb-5">
              <Col md={12}>
                <HistoricDetails symbol={underlyingSymbol} timePeriod={timePeriod} />
              </Col>
            </Row>
            <div className="mb-5">
              <InfoCardApy data={data} />
            </div>

            <Row className="justify-content-center mb-5">
              <Col md={6}>
                <RateHistoryChart symbol={underlyingSymbol} timePeriod={timePeriod} />
              </Col>
              <Col md={6}>
                <UtilizationChart symbol={underlyingSymbol} timePeriod={timePeriod} />
              </Col>
            </Row>
          </>
        );
    }
  }

  return (
    <>
      <div className="d-flex justify-content-center mb-5">
        <CryptoIcon
          name={underlyingSymbol}
          address={underlyingAddress}
          size="3.5rem"
          className="me-3"
        />
        <div>
          <h1 className="h2 m-0 p-0">{name}</h1>
          <div className="text-small gray">
            {underlyingSymbol}
            <ExternalLink
              href={`${etherscanUrl}address/${underlyingAddress}`}
              className="ms-2"
            >
              <CryptoIcon name="etherscan" />
            </ExternalLink>
          </div>
        </div>
      </div>

      <SegmentSwitch
        className="justify-content-center mb-5"
        createUrl={createUrl}
        options={segmentOptions}
      />

      {renderSegment(segment)}
    </>
  );
}

export default withErrorBoundary(Market);

import Loader from "../../components/Loader/Loader.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import Value from "../../components/Value/Value.js";
import ValueChange from "../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks.js";
import LiquidatorsTable from "./components/LiquidatorsTable.js";

function Liquidators(props) {
  const { timePeriod, setDaysAgo } = props;

  usePageTitle("Liquidators");

  const timeOptions = [
    { key: "1", value: "1 D" },
    { key: "7", value: "7 D" },
    { key: "30", value: "30 D" },
    { key: "90", value: "90 D" },
    { key: "365", value: "1 Y" },
  ];

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "liquidations/liquidators/",
    { days_ago: timePeriod }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }
  const { stats, change } = data;

  const statsCard = [
    {
      title: "liquidators",
      bigValue: (
        <>
          <Value value={stats.liquidators} decimals={0} className="me-2" />

          <ValueChange
            value={change.liquidators}
            tooltipValue={stats.liquidators - change.liquidators}
            decimals={0}
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "collateral liquidated",
      bigValue: (
        <>
          <Value
            value={stats.total_collateral}
            decimals={2}
            prefix="$"
            className="me-2"
            compact
          />
          <ValueChange
            value={change.total_collateral}
            tooltipValue={stats.total_collateral - change.total_collateral}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "debt repaid",
      bigValue: (
        <>
          <Value
            value={stats.total_debt}
            decimals={2}
            prefix="$"
            className="me-2"
            compact
          />
          <ValueChange
            value={change.total_debt}
            tooltipValue={stats.total_debt - change.total_debt}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "liquidator's profits",
      bigValue: (
        <>
          <Value
            value={stats.total_profits}
            decimals={2}
            prefix="$"
            className="me-2"
            compact
          />
          <ValueChange
            value={change.total_profits}
            tooltipValue={stats.total_profits - change.total_profits}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
          />
        </>
      ),
    },
  ];

  return (
    <>
      <TimeSwitch
        className="mb-5 justify-content-center"
        activeOption={timePeriod}
        onChange={setDaysAgo}
        options={timeOptions}
      />
      <div className="mb-5">
        <StatsBar stats={statsCard} />
      </div>
      <LiquidatorsTable timePeriod={timePeriod} />;
    </>
  );
}

export default withErrorBoundary(Liquidators);

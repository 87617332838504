import makeBlockie from "ethereum-blockies-base64";
import React from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Link, useLocation } from "react-router-dom";
import Address from "../../components/Address/Address.js";
import DateTimeAgo from "../../components/DateTime/DateTimeAgo.js";
import Loader from "../../components/Loader/Loader.js";
import SearchInput from "../../components/SearchInput/SearchInput.js";
import RemoteTable from "../../components/Table/RemoteTable.js";
import Value from "../../components/Value/Value.js";
import ValueChange from "../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle, useQueryParams } from "../../hooks.js";
import { parseUTCDateTime } from "../../utils/datetime.js";
import { smartLocationPrefix } from "../../utils/url.js";

function SDAI(props) {
  usePageTitle("sDAI");
  const queryParams = useQueryParams();
  const order = queryParams.get("order") || "-balance";
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  const searchText = queryParams.get("search");
  const pageSize = 25;
  const page = parseInt(queryParams.get("page")) || 1;

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `tokens/sDAI/`,
    {
      p: page,
      p_size: pageSize,
      order,
      search: searchText,
    }
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results } = data;

  return (
    <>
      <h1 className="mb-4 h2 text-center">sDAI holders</h1>
      <ToolkitProvider
        bootstrap4
        search
        keyField="balance"
        data={results}
        columns={[
          {
            dataField: "",
            text: "",
            formatter: (_, row) => {
              const blockie = makeBlockie(row.wallet_address);
              return <img src={blockie} alt={row.wallet_address} />;
            },
            classes: "blockie",
          },
          {
            dataField: "wallet_address",
            text: "Wallet",
            formatter: (cell) => (
              <Link to={`${prefix}wallets/${cell}/`} key={cell}>
                <Address value={cell} short />
              </Link>
            ),
            headerAlign: "left",
            align: "left",
          },

          {
            dataField: "balance",
            text: "Balance",
            sort: true,
            headerAlign: "right",
            align: "right",
            formatter: (cell) => (
              <Value value={cell} decimals={2} suffix={"sDAI"} compact />
            ),
          },
          {
            dataField: "change_1d",
            text: "1d change",
            sort: true,
            headerAlign: "right",
            align: "right",
            formatter: (cell) => <ValueChange value={cell} decimals={2} compact />,
          },
          {
            dataField: "change_7d",
            text: "7d change",
            sort: true,
            headerAlign: "right",
            align: "right",
            formatter: (cell) => <ValueChange value={cell} decimals={2} compact />,
          },
          {
            dataField: "change_30d",
            text: "30d change",
            sort: true,
            headerAlign: "right",
            align: "right",
            formatter: (cell) => <ValueChange value={cell} decimals={2} compact />,
          },
          {
            dataField: "datetime",
            text: "Last activity",
            sort: true,
            headerAlign: "right",
            align: "right",
            formatter: (cell) => <DateTimeAgo dateTime={parseUTCDateTime(cell)} />,
          },
        ]}
      >
        {(props) => (
          <div>
            <div className="react-bootstrap-table-search  justify-content-end d-flex align-items-center flex-wrap">
              <div>
                <SearchInput
                  placeholder="Search by address..."
                  initialSearchText={searchText}
                  {...props.searchProps}
                />
              </div>
            </div>
            <RemoteTable
              {...props.baseProps}
              loading={isPreviousData}
              page={page}
              pageSize={pageSize}
              totalPageSize={data.count}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
}

export default withErrorBoundary(SDAI);

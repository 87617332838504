import React, { useState } from "react";
import Graph from "../../../components/Graph/Graph.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import GraphSwitch from "../../../components/GraphSwitch/GraphSwitch.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { DateTime } from "luxon";

function MarketsChartLine(props) {
  const { timePeriod, symbol } = props;
  const [graphCurrency, setGraphCurrency] = useState("asset");

  const { data, isLoading, isError } = useFetch(`markets/${symbol}/historic-details/`, {
    days_ago: timePeriod,
  });

  const currencyOptions = [
    { key: "asset", value: <CryptoIcon name={symbol} /> },
    { key: "USD", value: "$" },
  ];

  const supplySeriesData = [];
  const borrowSeriesData = [];
  if (data) {
    data.forEach((row) => {
      supplySeriesData.push({
        x: row.dt,
        y: graphCurrency === "asset" ? row.total_supply : row.total_supply_usd,
      });
      borrowSeriesData.push({
        x: row.dt,
        y: graphCurrency === "asset" ? row.total_borrow : row.total_borrow_usd,
      });
    });
    const now = DateTime.now();
    supplySeriesData[supplySeriesData.length - 1].x = now;
    borrowSeriesData[borrowSeriesData.length - 1].x = now;
  }

  const usdSeries = [
    {
      data: supplySeriesData,
      label: "Total Supplied",
    },
    {
      data: borrowSeriesData,
      label: "Total Borrowed",
    },
  ];
  let prefix = "";
  let suffix = "";
  if (graphCurrency === "USD") {
    prefix = "$";
  } else if (graphCurrency === "asset") {
    suffix = " " + symbol;
  }

  const usdOptions = {
    maintainAspectRatio: false,
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: false,
        ticks: {
          callback: (value) => {
            if (graphCurrency === "USD") {
              return prefix + compact(value, 2, true);
            } else {
              return compact(value, 2, true);
            }
          },
          beginAtZero: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            const value = tooltipItem.parsed.y;
            const formattedValue = compact(value, 2, true);
            return `${prefix}${formattedValue} ${suffix}`;
          },
        },
      },
    },
  };
  /*do not remove either of the div bellow, or the graph will expand endlessly*/
  return (
    <LoadingCard
      title="supply / borrow history"
      minHeight
      isError={isError}
      isLoading={isLoading}
      data={data}
    >
      <div className="d-flex justify-content-end">
        <GraphSwitch
          label="Currency:"
          options={currencyOptions}
          onChange={(option) => setGraphCurrency(option)}
        />
      </div>
      <div>
        <Graph
          style={{ width: "100%", height: "33.33vh" }}
          series={usdSeries}
          options={usdOptions}
        />
      </div>
    </LoadingCard>
  );
}

export default withErrorBoundary(MarketsChartLine);

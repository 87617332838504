import makeBlockie from "ethereum-blockies-base64";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Address from "../../../components/Address/Address.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch, useSmartNavigate } from "../../../hooks.js";
import { smartLocationPrefix } from "../../../utils/url.js";
import { useLocation } from "react-router-dom";
import { parseUTCDateTime } from "../../../utils/datetime.js";
import { Col, Row } from "reactstrap";

function LiquidatorsTable() {
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(null);
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  let navigate = useSmartNavigate();
  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `liquidations/liquidators/table/`,
    {
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );

  const onRowClick = (e, row) => {
    navigate(e, `liquidators/${row.liquidator_address}/`);
  };
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const content = (
    <RemoteTable
      loading={isPreviousData}
      onRowClick={onRowClick}
      keyField="share"
      data={data.results}
      defaultSorted={[
        {
          dataField: "share",
          order: "desc",
        },
      ]}
      columns={[
        {
          dataField: "liquidator_address",
          text: "",
          formatter: (cell) => {
            const blockie = makeBlockie(cell);
            return <img src={blockie} alt={cell} />;
          },
          classes: "blockie",
        },
        {
          dataField: "liquidator_address",
          text: "Liquidator address",
          formatter: (cell, row) => (
            <Link
              to={`${prefix}wallets/${cell}/`}
              key={cell}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Address value={cell} short />
            </Link>
          ),
        },
        {
          dataField: "liquidations",
          text: "Liquidations",
          sort: true,
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "total_collateral",
          text: "Total collateral liquidated",
          sort: true,
          formatter: (cell) => <Value value={cell} decimals={2} prefix="$" compact />,
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "total_debt",
          text: "Total Debt Repaid",
          sort: true,
          formatter: (cell) => <Value value={cell} decimals={2} prefix="$" compact />,
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "total_profits",
          text: "Total Profits",
          sort: true,
          formatter: (cell) => <Value value={cell} decimals={2} prefix="$" compact />,
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "share",
          text: "Share",
          sort: true,
          formatter: (cell) => <Value value={cell} decimals={2} suffix="%" />,
          headerAlign: "right",
          align: "right",
        },
        {
          dataField: "last_active",
          text: "Last Active",
          formatter: (cell, row) => <DateTimeAgo dateTime={parseUTCDateTime(cell)} />,
          sort: true,
          headerAlign: "right",
          align: "right",
        },
      ]}
      page={page}
      pageSize={pageSize}
      totalPageSize={data.count}
      onSortChange={setOrder}
      onPageChange={setPage}
    />
  );
  return (
    <>
      <Row>
        <Col xl={12} className="mb-4">
          {content}
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(LiquidatorsTable);

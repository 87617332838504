import React from "react";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import StatsBar from "../../../components/Stats/StatsBar.js";
import ValueChange from "../../../components/Value/ValueChange.js";

function InfoCardApy(props) {
  const { data } = props;
  if (!data) {
    return null;
  }

  const { change = {} } = data;

  const totalStats = [
    {
      title: "deposit APY",
      bigValue: (
        <Value
          value={data.supply_apy * 100}
          decimals={2}
          suffix="%"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <ValueChange
          value={(data.supply_apy - change.supply_apy) * 100}
          decimals={2}
          suffix="%"
          hideIfZero
          tooltipValue={change.supply_apy * 100}
        />
      ),
    },
    {
      title: "borrow APY",
      bigValue: (
        <Value
          value={data.borrow_variable_apy * 100}
          decimals={2}
          suffix="%"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <ValueChange
          value={(data.borrow_variable_apy - change.borrow_variable_apy) * 100}
          decimals={2}
          suffix="%"
          hideIfZero
          tooltipValue={change.borrow_variable_apy * 100}
        />
      ),
    },

    {
      title: "utilization",
      normalValue: (
        <Value
          value={data.utilization_rate * 100}
          decimals={2}
          suffix="%"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <ValueChange
          value={(data.utilization_rate - change.utilization_rate) * 100}
          tooltipValue={change.utilization_rate * 100}
          decimals={2}
          suffix="%"
          hideIfZero
          className="text-small"
        />
      ),
    },
  ];
  return <StatsBar stats={totalStats} />;
}

export default withErrorBoundary(InfoCardApy);

import Table from "../../../components/Table/Table.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import ExternalLink from "../../../components/ExternalLink/ExternalLink.js";
import { useLocation } from "react-router-dom";
import { smartEtherscanUrl } from "../../../utils/url.js";
function RevenueTable({ data }) {
  const location = useLocation();
  const etherscanUrl = smartEtherscanUrl(location);

  const columns = [
    {
      header: "Beneficiary",
      cell: ({ row }) => (
        <>
          {row.key === "Maker" ? (
            <span className="bold">{row.key}</span>
          ) : row.key === "DDM" || row.key === "Treasury" ? (
            <ExternalLink href={`${etherscanUrl}address/${row.address}`}>
              <span>{row.key}</span>
            </ExternalLink>
          ) : (
            <span>{row.key}</span>
          )}
        </>
      ),

      headerAlign: "start",
      cellAlign: "start",
    },
    {
      header: "current",
      cell: ({ row }) => (
        <>
          <Value
            value={row.current_revenue}
            decimals={2}
            prefix={"$"}
            compact
            className={row.key === "Maker" ? "bold" : ""}
          />
        </>
      ),
      headerAlign: "start",
      cellAlign: "start",
    },
    {
      header: "monthly estimate",
      cell: ({ row }) => (
        <>
          <Value
            value={row.monthly_revenue}
            decimals={2}
            prefix={"$"}
            compact
            className={row.key === "Maker" ? "bold" : ""}
          />
        </>
      ),
      headerAlign: "start",
      cellAlign: "start",
    },
    {
      header: "yearly estimate",
      cell: ({ row }) => (
        <>
          <Value
            value={row.yearly_revenue}
            decimals={2}
            prefix={"$"}
            compact
            className={row.key === "Maker" ? "bold" : ""}
          />
        </>
      ),
      headerAlign: "end",
      cellAlign: "end",
    },
  ];
  return <Table data={data} keyField="key" columns={columns} />;
}

export default withErrorBoundary(RevenueTable);

import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { tooltipFooterTotal, tooltipLabelNumber } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
function DebtRepaidChart(props) {
  const { timePeriod } = props;
  const {
    data = {},
    isLoading,
    isError,
  } = useFetch("liquidations/debt-repaid/", { days_ago: timePeriod });

  const series = [];
  Object.entries(data).forEach(([key, rows]) => {
    series.push({
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row["debt_symbol"],
        y: row["collateral_seized_usd"],
      })),
    });
  });

  const options = {
    aspectRatio: 1.5,
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
      },
    },
    plugins: {
      tooltip: {
        filter: (tooltipItem) => {
          return tooltipItem.parsed.y !== 0 && tooltipItem.parsed.y !== null;
        },
        callbacks: {
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, "$");
          },
          footer: (tooltipItems) => {
            const currentItem = tooltipItems[0];

            if (
              !currentItem ||
              (currentItem.parsed.y === 0 && currentItem.parsed.y === null)
            ) {
              return null;
            }

            return tooltipFooterTotal(tooltipItems, "Total: $");
          },
        },
      },
    },
  };

  return (
    <LoadingCard
      minHeight
      title={"collateral liquidated per debt asset"}
      data={data}
      isError={isError}
      isLoading={isLoading}
    >
      <Graph series={series} options={options} type="bar" />
    </LoadingCard>
  );
}

export default withErrorBoundary(DebtRepaidChart);

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { SLASH_REGEX } from "../../utils/url.js";
import CryptoIcon from "../CryptoIcon/CryptoIcon.js";
import styles from "./NetworkSelector.module.scss";

function NetworkSelector(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const pathname = location.pathname.replace(SLASH_REGEX, "");
  const paths = pathname.split("/");

  const options = {
    v1: [
      {
        value: "ethereum",
        text: "Ethereum",
      },
      {
        value: "gnosis",
        text: "Gnosis",
      },
    ],
  };

  const currentVersion = paths[0];
  let currentNetwork;

  currentNetwork = options[paths[0]]?.find((network) => network.value === paths[1]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onItemClick = (version, network) => {
    let url = `/${version}/${network.value}/`;
    const path = paths.slice(2).join("/");
    if (path.length > 0) {
      url = url + path + "/";
    }

    navigate(url);
  };

  return (
    <div>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret tag="span" className={styles.dropdownToggle}>
          <CryptoIcon
            size="1rem"
            className="me-2"
            name={currentNetwork?.value}
            showTooltip={false}
          />
          {currentNetwork?.text}
        </DropdownToggle>
        <DropdownMenu>
          {options.v1.map((network) => (
            <DropdownItem
              key={`v1-${network.value}`}
              active={currentVersion === "v1" && currentNetwork.value === network.value}
              onClick={() => {
                onItemClick("v1", network);
              }}
            >
              <CryptoIcon
                size="1rem"
                className="me-2"
                name={network.value}
                showTooltip={false}
              />
              {network.text}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default NetworkSelector;

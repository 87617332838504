import React from "react";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import StatsBar from "../../../components/Stats/StatsBar.js";

function InfoCard(props) {
  const { data } = props;
  if (!data) {
    return null;
  }

  const totalStats = [
    {
      title: "LTV",
      bigValue: (
        <Value value={data.ltv * 100} decimals={2} suffix="%" className="text-big" />
      ),
    },
    {
      title: "liquidation threshold",
      bigValue: (
        <Value
          value={data.liquidation_threshold * 100}
          decimals={2}
          suffix="%"
          className="text-big"
        />
      ),
    },
    {
      title: "reserve factor",
      bigValue: (
        <Value
          value={data.reserve_factor * 100}
          decimals={2}
          suffix="%"
          className="text-big"
        />
      ),
    },
    {
      title: "liquidation penalty",
      bigValue: (
        <Value
          value={
            Number(data.liquidation_bonust) > 1
              ? (data.liquidation_bonust - 1) * 100
              : data.liquidation_bonust
          }
          decimals={2}
          suffix="%"
          className="text-big"
        />
      ),
    },
  ];
  return <StatsBar stats={totalStats} />;
}

export default withErrorBoundary(InfoCard);

import PropTypes from "prop-types";
import React, { useState } from "react";
import classnames from "classnames";
import styles from "./SegmentSwitch.module.scss";
import { useNavigate, useParams } from "react-router-dom";

function SegmentSwitch(props) {
  let { label, className, activeOption, options, createUrl, basic, ...rest } = props;

  if (!options) {
    options = [
      { key: "", value: "segment 1" },
      { key: "segment2", value: "segment 2" },
      { key: "segment3", value: "segment 3" },
    ];
  }

  const params = useParams();
  const segment = params.segment || options[0].key;
  const [active, setActive] = useState(segment);

  const navigate = useNavigate();

  const onOptionClick = (optionKey) => {
    setActive(optionKey);
    const newUrl = createUrl(params, optionKey);
    navigate(newUrl);
  };

  return (
    <div className={classnames(styles.segmentSwitch, className)}>
      {label ? <label className={styles.segmentSwitchLabel}>{label}</label> : null}
      <ul className={styles.segmentSwitchContainer} {...rest}>
        {options.map((option) => (
          <li
            key={option.key}
            className={classnames(styles.segmentSwitchItem, {
              [styles.segmentSwitchItemActive]: option.key === active,
            })}
            onClick={() => onOptionClick(option.key)}
          >
            {option.value}
          </li>
        ))}
      </ul>
    </div>
  );
}

SegmentSwitch.propTypes = {
  label: PropTypes.any,
  onChange: PropTypes.func,
  activeOption: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.array,
};

export default SegmentSwitch;

import { useParams, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Loader from "../../components/Loader/Loader.js";
import SegmentSwitch from "../../components/SegmentSwitch/SegmentSwitch.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import Title from "../../components/Title/Title.js";
import Value from "../../components/Value/Value.js";
import ValueChange from "../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks";
import Liquidators from "./Liquidators.js";
import DebtRepaidChart from "./components/DebtRepaidChart.js";
import LiquidationsChart from "./components/LiquidationsChart.js";
import LiquidationsCollateralChart from "./components/LiquidationsCollateralChart.js";
import LiquidationsDebtChart from "./components/LiquidationsDebtChart.js";
import LiquidationsTable from "./components/LiquidationsTable.js";
import { useShowQueryParams } from "../../hooks";
import { smartLocationPrefix } from "../../utils/url.js";

function Liquidations(props) {
  usePageTitle("Liquidations");
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  const { queryParams, setQueryParams } = useShowQueryParams();
  const timePeriod = queryParams.get("days_ago") || "1";
  const switchChange = (newValue) => setQueryParams({ days_ago: newValue });
  const { segment } = useParams();
  const timeOptions = [
    { key: "1", value: "1 D" },
    { key: "7", value: "7 D" },
    { key: "30", value: "30 D" },
    { key: "90", value: "90 D" },
    { key: "365", value: "1 Y" },
  ];

  const segmentOptions = [
    { key: "", value: "Liquidations" },
    { key: "liquidators", value: "Liquidators" },
  ];

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "liquidations/stats/",
    { days_ago: timePeriod },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { stats, change, results } = data;

  const createUrl = (params, optionKey) => {
    return `${prefix}liquidations/${optionKey}`;
  };

  const statsData = [
    {
      title: "liquidations",
      bigValue: (
        <>
          <Value value={stats.count} decimals={0} compact className="me-2" />
          <ValueChange
            value={change.count}
            tooltipValue={stats.count - change.count}
            decimals={0}
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "collateral liquidated",
      bigValue: (
        <>
          <Value
            value={stats.collateral_seized || 0}
            decimals={2}
            prefix="$"
            className="me-2"
            compact
          />
          <ValueChange
            value={change.collateral_seized || 0}
            tooltipValue={stats.collateral_seized - change.collateral_seized}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
          />
        </>
      ),
    },
    {
      title: "debt repaid",
      bigValue: (
        <>
          <Value
            value={stats.debt_repaid || 0}
            decimals={2}
            prefix="$"
            className="me-2"
            compact
          />
          <ValueChange
            value={change.debt_repaid || 0}
            tooltipValue={stats.debt_repaid - change.debt_repaid}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
          />
        </>
      ),
    },
  ];

  function renderSegment(segment) {
    switch (segment) {
      case "liquidators":
        return (
          <>
            <Title tag="h2">Liquidators</Title>
            <Liquidators timePeriod={timePeriod} setDaysAgo={switchChange} />
          </>
        );
      default:
        return (
          <>
            <Title tag="h2">Liquidations</Title>

            <TimeSwitch
              className="mb-5 justify-content-center"
              activeOption={timePeriod}
              onChange={switchChange}
              options={timeOptions}
            />

            <StatsBar stats={statsData} className="mb-5" />
            {change.count > 0 ? (
              <>
                <Row>
                  <Col md={12} className="mb-5">
                    <LiquidationsTable timePeriod={timePeriod} />
                  </Col>
                  <Col md={6} className="mb-5">
                    <LiquidationsChart timePeriod={timePeriod} results={results} />
                  </Col>
                  <Col md={6} className="mb-5">
                    <DebtRepaidChart timePeriod={timePeriod} />
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-5">
                    <LiquidationsCollateralChart timePeriod={timePeriod} />
                  </Col>
                  <Col md={6} className="mb-5">
                    <LiquidationsDebtChart timePeriod={timePeriod} />
                  </Col>
                </Row>
              </>
            ) : (
              <div className="h4 text-center mb-5">
                No liquidations in{" "}
                {Number(timePeriod) === 1
                  ? "the last day"
                  : "the last " + timePeriod + " days"}
                .
              </div>
            )}
          </>
        );
    }
  }

  return (
    <>
      <SegmentSwitch
        className="justify-content-center mb-5"
        createUrl={createUrl}
        options={segmentOptions}
      />
      {renderSegment(segment)}
    </>
  );
}

export default withErrorBoundary(Liquidations);

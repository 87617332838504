import React from "react";
import Loader from "../../../components/Loader/Loader.js";
import StatsBar from "../../../components/Stats/StatsBar.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";

function LiquidatorStats(props) {
  const { address } = props;
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `liquidations/liquidators/${address}/stats/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const statsCard = [
    {
      title: "liquidations",
      bigValue: <Value value={data.count} decimals={0} />,
    },
    {
      title: "collateral liquidated",
      bigValue: <Value value={data.total_collateral} decimals={2} prefix="$" compact />,
    },
    {
      title: "debt repaid",
      bigValue: <Value value={data.total_debt} decimals={2} prefix="$" compact />,
    },
    {
      title: "liquidator profits",
      bigValue: <Value value={data.total_profits} decimals={2} prefix="$" compact />,
    },
  ];
  return <StatsBar stats={statsCard} />;
}

export default withErrorBoundary(LiquidatorStats);

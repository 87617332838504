import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";

import Loader from "../../../components/Loader/Loader.js";
import React from "react";
import AavePaymentsTable from "./AavePaymentsTable.js";
import RevenueTable from "./RevenueTable.js";
import Card from "../../../components/Card/Card.js";

import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import ExternalLink from "../../../components/ExternalLink/ExternalLink.js";
function AaveSection(props) {
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "revshare/aave-quaterly/"
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }
  const { payouts } = data;
  const { projection } = data;

  return (
    <>
      {payouts.length > 0 ? (
        <>
          <Card
            title={
              <>
                <ExternalLink
                  href="https://mips.makerdao.com/mips/details/MIP106#9-4-1-spark-protocol-aave-revenue-share"
                  className="nav-link"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  aave revenue
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className={classnames("link gray ms-2", { small: true })}
                  />
                </ExternalLink>
              </>
            }
            className="mb-3"
          >
            <RevenueTable data={projection} />
          </Card>
          <div>
            <AavePaymentsTable data={payouts} />
          </div>
        </>
      ) : null}
    </>
  );
}
export default withErrorBoundary(AaveSection);

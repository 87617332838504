import makeBlockie from "ethereum-blockies-base64";

import Address from "../../../components/Address/Address.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Value from "../../../components/Value/Value.js";
import { useSmartNavigate } from "../../../hooks";

function AtRiskPositionsTable(props) {
  const navigate = useSmartNavigate();
  const { data, count, page, setPage } = props;

  let pageSize = 10;

  const onRowClick = (e, row) => {
    navigate(e, `wallets/${row.address}/`);
  };

  return (
    <>
      <RemoteTable
        keyField="id"
        data={data}
        onRowClick={onRowClick}
        columns={[
          {
            dataField: "",
            text: "",
            formatter: (_, row) => {
              const blockie = makeBlockie(row.address);
              return <img src={blockie} alt={row.address} />;
            },
            classes: "blockie",
          },
          {
            dataField: "address",
            text: "Wallet address",
            formatter: (cell, row) => <Address value={cell} short />,
          },
          {
            dataField: "collateral_amount_usd",
            text: "collateral liquidated",
            formatter: (cell, row) => (
              <>
                <div className="d-flex align-items-center">
                  <CryptoIcon
                    name={row.collateral_symbol}
                    size="2em"
                    className="me-3"
                  />
                  <div>
                    <Value
                      value={row.collateral_amount}
                      decimals={2}
                      suffix={row.collateral_symbol}
                      compact
                    />
                    <br />
                    <Value
                      value={cell}
                      decimals={2}
                      className="text-small gray"
                      prefix="$"
                      compact
                    />
                  </div>
                </div>
              </>
            ),
          },
          {
            dataField: "debt_amount_usd",
            text: "Debt Repaid",
            headerAlign: "left",
            align: "left",
            formatter: (cell, row) => (
              <>
                <div className="d-flex align-items-center">
                  <CryptoIcon name={row.debt_symbol} size="2em" className="me-3" />
                  <div>
                    <Value
                      value={row.debt_amount}
                      decimals={2}
                      suffix={row.debt_symbol}
                      compact
                    />
                    <br />
                    <Value
                      value={cell}
                      decimals={2}
                      className="text-small gray"
                      prefix="$"
                      compact
                    />
                  </div>
                </div>
              </>
            ),
          },
          {
            dataField: "debt_amount_usd",
            text: "incentive",
            formatter: (cell, row) => (
              <Value
                value={row.collateral_amount_usd - cell}
                decimals={2}
                prefix="$"
                compact100k
              />
            ),
            headerAlign: "left",
            align: "left",
          },
        ]}
        page={page}
        pageSize={pageSize}
        totalPageSize={count}
        onPageChange={setPage}
      />
    </>
  );
}

export default AtRiskPositionsTable;

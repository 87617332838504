import PropTypes from "prop-types";
import React, { useState } from "react";
import Slider from "rc-slider";
import classnames from "classnames";
import styles from "./RangeSlider.module.scss";

function RangeSlider(props) {
  const {
    onChange,
    title,
    defaultValue,
    min,
    max,
    className,
    formatter,
    minPrefix,
    maxPrefix,
    minSuffix,
    maxSuffix,
    ...rest
  } = props;

  const [sliderValue, setSliderValue] = useState(defaultValue || [min, max]);

  const onSliderChange = (value) => {
    setSliderValue(value);
  };

  const onAfterSliderChange = (value) => {
    setSliderValue(value);
    onChange(value);
  };

  return (
    <div className={classnames(styles.rangeSlider, className)}>
      <div className="d-flex">
        <div className={styles.rangeSliderMin}>
          {minPrefix}
          {formatter(sliderValue[0])}
          {minSuffix}
        </div>
        <div
          className={classnames(styles.rangeSliderTitle, "flex-grow-1", "text-center")}
        >
          {title}
        </div>
        <div className={styles.rangeSliderMax}>
          {maxPrefix}
          {formatter(sliderValue[1])}
          {maxSuffix}
        </div>
      </div>
      <Slider
        defaultValue={defaultValue}
        min={min}
        max={max}
        onChange={onSliderChange}
        onAfterChange={onAfterSliderChange}
        range
        {...rest}
      />
    </div>
  );
}

RangeSlider.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  defaultValue: PropTypes.array,
  min: PropTypes.any.isRequired,
  max: PropTypes.any.isRequired,
  className: PropTypes.string,
  formatter: PropTypes.func.isRequired,
  minPrefix: PropTypes.string,
  maxPrefix: PropTypes.string,
  minSuffix: PropTypes.string,
  maxSuffix: PropTypes.string,
};

RangeSlider.defaultProps = {
  min: 1,
  max: 10,
  formatter: (value) => {
    return value;
  },
};

export default RangeSlider;

import _ from "lodash";
import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { tooltipLabelNumber } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function CollateralAtRiskChart(props) {
  const { data, type, chartType } = props;
  const priceChange = type === "long" ? "price decrease" : "price increase";
  const grouped = _.groupBy(data, "underlying_symbol");
  const series = [];

  let y;
  if (chartType === "bar") {
    y = "amount_usd";
  } else {
    y = "total_amount_usd";
  }

  let prefix = "";
  let suffix = "";
  let text = "USD";

  prefix = "$";

  Object.entries(grouped).forEach(([key, rows]) => {
    let item = {
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row["price_change"],
        y: row[y],
      })),
    };
    series.push(item);
  });

  const options = {
    orderSeries: true,
    fill: true,
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        stacked: true,
        reverse: type === "short" ? false : true,
        type: "linear",
        ticks: {
          callback: (value) => `${value}%`,
        },
        title: {
          display: true,
          text: priceChange,
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => prefix + compact(value, 2, true),
        },
        title: {
          display: true,
          text: `collateral ${text} amount at risk`,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return `At ${tooltipItems[0].parsed.x}% markets ${priceChange}`;
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, prefix);
          },
          footer: (tooltipItems) => {
            const total = tooltipItems.reduce(
              (total, tooltip) => total + tooltip.parsed.y,
              0
            );
            const formattedValue = compact(total, 2, true);
            return `Total: ${prefix}${formattedValue}${suffix}`;
          },
        },
      },
    },
  };

  return <Graph series={series} options={options} type={chartType} />;
}

export default withErrorBoundary(CollateralAtRiskChart);

import _ from "lodash";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { DateTime } from "luxon";

function GraphComponent({ data }) {
  const { results } = data;

  const grouped = _.groupBy(results, "underlying_symbol");

  const series = [];
  const now = DateTime.now();

  Object.entries(grouped).forEach(([key, rows]) => {
    let item = {
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row["datetime"],
        y: row.total_supply_usd,
      })),
    };

    series.push(item);
  });
  series.forEach((item) => {
    item.data[item.data.length - 1].x = now;
  });
  const options = {
    orderSeries: true,
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "x",
    },
    fill: true,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem);
          },
          footer: (tooltipItems) => {
            const total = tooltipItems.reduce(
              (total, tooltip) => total + tooltip.parsed.y,
              0
            );
            return `Total: $` + compact(total, 2, true);
          },
        },
      },
    },
  };

  return <Graph series={series} options={options} />;
}

function SupplyHistoryChart(props) {
  const { data, isLoading, isError } = useFetch(`markets/history/`, {
    what: "supply",
  });

  return (
    <LoadingCard isError={isError} isLoading={isLoading} data={data} title="Supplied">
      <GraphComponent data={data} />
    </LoadingCard>
  );
}

export default withErrorBoundary(SupplyHistoryChart);

import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { compact } from "../../../utils/number.js";
import { SYMBOLS_PALETTE } from "../../../utils/colors.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
function AssetAsCollateralSankey(props) {
  const { symbol } = props;
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/collateral-backed/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  } else if (data.length === 0) {
    return <> </>;
  }
  var sankeyData = [];
  var existingKeys = [];
  data.forEach((row) => {
    if (!existingKeys.includes(row["key"])) {
      sankeyData.push({
        to: row["key"],
        // hack for showing the flow from WETH -> WETH otherwise the flow doesn't exist
        from: row["from"] === symbol ? symbol + " " : row["from"],
        flow: Number(row["amount"]),
      });
    }
    existingKeys.push(row["key"]);
  });

  function getColor(symbol) {
    return SYMBOLS_PALETTE[symbol] || "#0e1726";
  }

  const series = [
    {
      data: sankeyData,
      colorFrom: (c) => getColor(c.dataset.data[c.dataIndex].from),
      colorTo: (c) => getColor(c.dataset.data[c.dataIndex].to),
    },
  ];

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const item = tooltipItem.dataset.data[tooltipItem.dataIndex];
            return item.from + " -> " + item.to + " : $" + compact(item.flow);
          },
        },
      },
    },
  };
  return (
    <LoadingCard
      minHeight
      title={"Current liabilities"}
      isLoading={isLoading}
      isError={isError}
      data={data}
    >
      <Graph series={series} options={options} type="sankey" />
    </LoadingCard>
  );
}

export default withErrorBoundary(AssetAsCollateralSankey);

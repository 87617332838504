import { Col, Row } from "reactstrap";
import Loader from "../../components/Loader/Loader.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import TimeSwitch from "../../components/TimeSwitch/TimeSwitch.js";
import Title from "../../components/Title/Title.js";
import Value from "../../components/Value/Value.js";
import ValueChange from "../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../hoc.js";
import { useFetch, usePageTitle } from "../../hooks";
import BorrowHistoryChart from "./components/BorrowHistoryChart.js";
import CollateralAtRiskSection from "./components/CollateralAtRiskSection.js";
import MarketsTable from "./components/MarketsTable.js";
import SupplyHistoryChart from "./components/SupplyHistoryChart.js";
import { useShowQueryParams } from "../../hooks";
function Homepage(props) {
  usePageTitle("Spark");

  const { queryParams, setQueryParams } = useShowQueryParams();
  const timePeriod = queryParams.get("days_ago") || "1";
  const switchChange = (newValue) => setQueryParams({ days_ago: newValue });
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "markets/stats/",
    { days_ago: timePeriod }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  let options = [
    { key: "1", value: "1 D" },
    { key: "7", value: "7 D" },
    { key: "30", value: "30 D" },
    { key: "90", value: "90 D" },
    { key: "365", value: "1 Y" },
  ];

  const { stats } = data;

  const statsCard = [
    {
      title: "supplied",
      bigValue: (
        <>
          <Value
            value={stats.supply}
            decimals={2}
            prefix="$"
            compact
            className="me-2"
          />
          <ValueChange
            value={stats.supply - stats.supply_change}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
            tooltipValue={stats.supply_change}
          />
        </>
      ),
    },
    {
      title: "borrowed",
      bigValue: (
        <>
          <Value
            value={stats.borrow}
            decimals={2}
            prefix="$"
            compact
            className="me-2"
          />
          <ValueChange
            value={stats.borrow - stats.borrow_change}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
            tooltipValue={stats.borrow_change}
          />
        </>
      ),
    },
    {
      title: "available liquidity",
      bigValue: (
        <>
          <Value value={stats.tvl} decimals={2} prefix="$" compact className="me-2" />
          <ValueChange
            value={stats.tvl - stats.tvl_change}
            decimals={2}
            prefix="$"
            compact
            hideIfZero
            tooltipValue={stats.tvl_change}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <TimeSwitch
        className="mb-5 justify-content-center"
        activeOption={timePeriod}
        label={""}
        onChange={switchChange}
        options={options}
      />

      <StatsBar stats={statsCard} className="mb-5" />
      <Row className="mb-5">
        <Col xs="12" md="6">
          <SupplyHistoryChart />
        </Col>
        <Col xs="12" md="6">
          <BorrowHistoryChart />
        </Col>
      </Row>

      <Title className={"mb-5"} tag="h2">
        Markets
      </Title>
      <MarketsTable daysAgo={timePeriod} />

      <CollateralAtRiskSection />
    </div>
  );
}

export default withErrorBoundary(Homepage);

import { Col, Row } from "reactstrap";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import TimeSwitch from "../../../components/TimeSwitch/TimeSwitch.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import CollateralAtRiskChart from "./CollateralAtRiskChart.js";
import { useShowQueryParams } from "../../../hooks.js";
import MarketAtRiskHistoryChart from "./MarketAtRiskHistoryChart.js";

function CollateralAtRiskSection(props) {
  const { symbol } = props;
  const { queryParams, setQueryParams } = useShowQueryParams();
  const positionsType = queryParams.get("positions_type") || "long";
  const switchChange = (newValue) => setQueryParams({ positions_type: newValue });

  const {
    data = {},
    isLoading,
    isError,
  } = useFetch(`markets/${symbol}/at-risk/`, { type: positionsType });

  const { results } = data;

  return (
    <div>
      <TimeSwitch
        className="mb-5 justify-content-center"
        activeOption={positionsType}
        label={""}
        onChange={switchChange}
        options={[
          { key: "long", value: "price decrease" },
          { key: "short", value: "price increase" },
        ]}
      />
      {results === undefined || results.length === 0 ? (
        <div className="text-center mb-5 text-white">
          No collateral at risk in case of price{" "}
          {positionsType === "short" ? "increase" : "decrease"}.
        </div>
      ) : (
        <>
          <Row>
            <Col md="6">
              <LoadingCard
                minHeight
                title="Cumulative per drop"
                isError={isError}
                isLoading={isLoading}
                data={results}
              >
                <CollateralAtRiskChart
                  data={results}
                  type={positionsType}
                  symbol={symbol}
                  chartType={"line"}
                />
              </LoadingCard>
            </Col>
            <Col md="6">
              <LoadingCard
                minHeight
                title="per drop"
                isError={isError}
                isLoading={isLoading}
                data={results}
              >
                <CollateralAtRiskChart
                  data={results}
                  type={positionsType}
                  symbol={symbol}
                  chartType={"bar"}
                />
              </LoadingCard>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md="12">
              <LoadingCard
                title="cumulative per drop - history"
                isLoading={isLoading}
                isError={isError}
                data={results}
              >
                <MarketAtRiskHistoryChart
                  data={results}
                  type={positionsType}
                  symbol={symbol}
                  chartType={"bar"}
                />
              </LoadingCard>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default withErrorBoundary(CollateralAtRiskSection);

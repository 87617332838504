import styles from "./InlineSelect.module.scss";

const InlineSelect = ({ options, value, onChange, ...rest }) => {
  return (
    <select
      className={styles.custom_select}
      value={value}
      onChange={onChange}
      {...rest}
    >
      {options.map((option) => (
        <option key={option.key} value={option.key}>
          {option.value}
        </option>
      ))}
    </select>
  );
};

export default InlineSelect;

import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { DateTime } from "luxon";

function RateHistoryChart(props) {
  const { symbol, timePeriod } = props;
  const { data, isLoading, isError } = useFetch(`markets/${symbol}/historic-details/`, {
    days_ago: timePeriod,
  });

  const borrow = [];
  const supply = [];
  const stable_borrow_apy = [];
  if (data) {
    data.forEach((row) => {
      borrow.push({
        x: row.dt,
        y: row.borrow_apy * 100,
      });
      supply.push({
        x: row.dt,
        y: row.supply_apy * 100,
      });
      if (row.stable_borrow_rate_enabled === true) {
        stable_borrow_apy.push({
          x: row.dt,
          y: row.stable_borrow_apy * 100,
        });
      }
    });
    const now = DateTime.now();
    supply[supply.length - 1].x = now;
    borrow[borrow.length - 1].x = now;
    if (stable_borrow_apy.length > 0) {
      borrow[borrow.length - 1].x = now;
    }
  }

  const series = [
    {
      label: "deposit APY",
      data: supply,
    },
    {
      label: "borrow APY",
      data: borrow,
    },
  ];
  if (stable_borrow_apy.length > 0) {
    series.push({
      label: "stable borrow APY",
      data: stable_borrow_apy,
    });
  }

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: false,
        ticks: {
          callback: (value) => compact(value, 2, true) + "%",
        },
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            const value = tooltipItem.parsed.y;
            return value !== null ? value.toFixed(2) + "%" : "";
          },
        },
      },
    },
  };

  return (
    <LoadingCard
      title="rates history"
      minHeigh
      isError={isError}
      isLoading={isLoading}
      data={data}
    >
      <Graph series={series} options={options} />
    </LoadingCard>
  );
}

export default withErrorBoundary(RateHistoryChart);

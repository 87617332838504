import { faInfinity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import makeBlockie from "ethereum-blockies-base64";
import queryString from "query-string";
import React, { useState } from "react";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Address from "../../components/Address/Address.js";
import DateTimeAgo from "../../components/DateTime/DateTimeAgo.js";
import Info from "../../components/Info/Info.js";
import Loader from "../../components/Loader/Loader.js";
import RangeSlider from "../../components/RangeSlider/RangeSlider.js";
import SearchInput from "../../components/SearchInput/SearchInput.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import Switch from "../../components/Switch/Switch.js";
import RemoteTable from "../../components/Table/RemoteTable.js";
import Value from "../../components/Value/Value.js";
import { withErrorBoundary } from "../../hoc.js";
import {
  useFetch,
  usePageTitle,
  useQueryParams,
  useSmartNavigate,
} from "../../hooks.js";
import { parseUTCDateTime } from "../../utils/datetime.js";
import { compact, formatToDecimals } from "../../utils/number.js";
import { getAllQueryParams } from "../../utils/url.js";
import { useNavigate } from "react-router-dom";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
function Wallets(props) {
  usePageTitle("Wallets");

  const smartNavigate = useSmartNavigate();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const pageSize = 25;
  const page = parseInt(queryParams.get("page")) || 1;
  const searchText = queryParams.get("search");
  const qParams = getAllQueryParams(queryParams);
  const borrow = queryParams.get("borrow");
  const empty = queryParams.get("empty");

  const [healthRateValue, setHealthRateValue] = useState([
    parseInt(queryParams.get("minHealthRate")) || 1,
    parseInt(queryParams.get("maxHealthRate")) || 5,
  ]);
  const [BorrowValue, setBorrowValue] = useState([
    parseInt(queryParams.get("minBorrow")) || 0,
    parseInt(queryParams.get("maxBorrow")) || 100000000,
  ]);

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    "wallets/",
    {
      p: page,
      p_size: pageSize,
      order: queryParams.get("order") || "-borrow",
      search: searchText,
      min_health_rate: healthRateValue[0],
      max_health_rate: healthRateValue[1],
      min_borrow: BorrowValue[0],
      max_borrow: BorrowValue[1],
      non_insolvent: queryParams.get("insolvent"),
      supply_borrow: queryParams.get("supply_borrow"),
      no_dust: queryParams.get("no_dust"),
      borrow: borrow,
      empty: empty,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results } = data;
  const { min_health_rate: minHealthRate, max_health_rate: maxHealthRate } = results;
  const onBorrowSliderChange = (value) => {
    setBorrowValue(value);

    const newParams = {
      ...qParams,
      minBorrow: value[0],
      maxBorrow: value[1],
      page: 1,
    };
    let qs = queryString.stringify(newParams, { skipNull: true });
    if (qs) {
      qs = `?${qs}`;
      navigate(qs);
    }
  };

  const onHealthRateSliderChange = (value) => {
    setHealthRateValue(value);

    const newParams = {
      ...qParams,
      minHealthRate: value[0],
      maxHealthRate: value[1],
      page: 1,
    };

    if (value[1] === 5) {
      // check if max_health_rate is set to 5
      newParams.search = ""; // reset the search parameter to clear all filters
    }
    let qs = queryString.stringify(newParams, { skipNull: true });
    if (qs) {
      qs = `?${qs}`;
      navigate(qs);
    }
  };

  const onCheckboxChange = (param, value) => {
    const newParams = { ...qParams, page: null, [param]: value || null };
    let qs = queryString.stringify(newParams, { skipNull: true });
    if (qs) {
      qs = `?${qs}`;
      navigate(qs);
    } else {
      navigate("./");
    }
  };

  const statsCard = [
    {
      title: "wallet count",
      bigValue: (
        <>
          <Value value={data.total_wallets} decimals={0} />
        </>
      ),
    },
    {
      title: "total supplied",
      bigValue: (
        <>
          <Value value={data.total_supply || 0} decimals={2} prefix="$" compact />
        </>
      ),
    },
    {
      title: "total borrowed",
      bigValue: (
        <>
          <Value value={data.total_borrow || 0} decimals={2} prefix="$" compact />
        </>
      ),
    },
  ];

  const onRowClick = (e, row) => {
    smartNavigate(e, `wallets/${row.address}/`);
  };

  return (
    <>
      <h1 className="mb-4 h2 text-center">Wallets</h1>
      <StatsBar stats={statsCard} className="mb-5" />
      <ToolkitProvider
        bootstrap4
        search
        keyField="address"
        data={results}
        columns={[
          {
            dataField: "",
            text: "",
            formatter: (_, row) => {
              const blockie = makeBlockie(row.address);
              return <img src={blockie} alt={row.address} />;
            },
            classes: "blockie",
          },
          {
            dataField: "address",
            text: "Address",
            formatter: (cell) => <Address value={cell} short />,
          },
          {
            dataField: "collateral_assets",
            text: "Supply Assets",
            formatter: (cell) => (
              <>
                {cell
                  ? cell
                      .filter((asset) => asset !== null)
                      .map((asset) => (
                        <CryptoIcon
                          name={asset}
                          key={asset}
                          className="me-1"
                          size="1.5rem"
                        />
                      ))
                  : null}
              </>
            ),
            footer: () => null,
            headerAlign: "right",
            align: "right",
          },

          {
            dataField: "supply",
            text: "supply",
            sort: true,
            formatter: (cell, row) => (
              <Value value={cell} decimals={2} prefix={"$"} compact dashIfZero />
            ),

            headerAlign: "right",
            align: "right",
          },

          {
            dataField: "debt_assets",
            text: "Borrow Assets",
            formatter: (cell) => (
              <>
                {cell
                  ? cell
                      .filter((asset) => asset !== null)
                      .map((asset) => (
                        <CryptoIcon
                          name={asset}
                          key={asset}
                          className="me-1"
                          size="1.5rem"
                        />
                      ))
                  : null}
              </>
            ),
            footer: () => null,
            headerAlign: "right",
            align: "right",
          },

          {
            dataField: "borrow",
            text: "borrow",
            sort: true,
            formatter: (cell, row) => (
              <Value value={cell} decimals={2} prefix={"$"} compact dashIfZero />
            ),

            headerAlign: "right",
            align: "right",
          },

          {
            dataField: "health_rate",
            text: "Health rate",
            sort: true,
            formatter: (cell, row) => {
              if (cell >= 1000) {
                return <FontAwesomeIcon icon={faInfinity} />;
              } else if (cell) {
                return <Value value={cell} decimals={3} />;
              } else {
                return <FontAwesomeIcon icon={faInfinity} />;
              }
            },
            headerAlign: "right",
            align: "right",
          },

          {
            dataField: "liquidation_price_change",
            text: (
              <>
                price change{" "}
                <Info title="Price Change" iconSmall={false}>
                  Wallet becomes liquidatable at this market wide price change.
                </Info>
              </>
            ),
            sort: true,
            formatter: (cell, row) => {
              if (
                Number(row.borrow) === 0 ||
                Number(row.liquidation_price_change) === -100
              ) {
                return "-";
              } else if (cell === null) {
                return "-";
              } else {
                return <Value value={cell} decimals={0} suffix="%" />;
              }
            },
            headerAlign: "right",
            align: "right",
          },
          {
            dataField: "last_activity",
            text: "latest activity",
            sort: true,
            formatter: (cell, row) => (
              <DateTimeAgo dateTime={parseUTCDateTime(cell)} inDays />
            ),
            headerAlign: "right",
            align: "right",
          },
        ]}
      >
        {(props) => (
          <div>
            <div className="react-bootstrap-table-search d-flex align-items-center flex-wrap">
              <RangeSlider
                defaultValue={[
                  healthRateValue[0] || minHealthRate,
                  healthRateValue[1] || maxHealthRate,
                ]}
                step={0.1}
                min={minHealthRate}
                max={5}
                maxSuffix={"+"}
                formatter={(value) => {
                  return formatToDecimals(value, 2);
                }}
                title="HEALTH RATE"
                onChange={onHealthRateSliderChange}
                className="flex-grow-1 me-5 mb-3"
              />
              <RangeSlider
                defaultValue={[BorrowValue[0], BorrowValue[1]]}
                step={1000000}
                min={0}
                max={100000000}
                maxSuffix={"+"}
                minPrefix={"$"}
                maxPrefix={"$"}
                formatter={(value) => {
                  return compact(value);
                }}
                prefix={"$"}
                title="BORROW VALUE"
                onChange={onBorrowSliderChange}
                className="flex-grow-1 me-5 mb-3"
              />

              <Switch
                label="hide wallets without borrow"
                className="me-5 mb-3"
                style={{ marginTop: "15px" }}
                checked={borrow === "1"}
                onCheckedChange={(checked) => onCheckboxChange("borrow", checked)}
              />
              <Switch
                label="hide empty wallets"
                className="me-5 mb-3"
                style={{ marginTop: "15px" }}
                checked={empty === "1"}
                onCheckedChange={(checked) => onCheckboxChange("empty", checked)}
              />
              <div>
                <SearchInput
                  placeholder="Search by address..."
                  initialSearchText={searchText}
                  {...props.searchProps}
                />
              </div>
            </div>
            <RemoteTable
              {...props.baseProps}
              loading={isPreviousData}
              onRowClick={onRowClick}
              page={page}
              pageSize={pageSize}
              totalPageSize={data.count}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
}

export default withErrorBoundary(Wallets);

import Loader from "../../components/Loader/Loader.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import Value from "../../components/Value/Value.js";
import { withErrorBoundary } from "../../hoc.js";
import { Row, Col } from "reactstrap";
import { useFetch, usePageTitle, useShowQueryParams } from "../../hooks.js";
import AtRiskPositionsTable from "./components/AtRiskPositionsTable.js";
import AggregatedAtRiskChart from "./components/AggregatedAtRiskChart.js";
import AssetForm from "./components/AssetForm.js";
import { useState } from "react";

function LiquidationSimulator(props) {
  usePageTitle("Liquidation Simulator");
  const { queryParams, setQueryParams } = useShowQueryParams({ WETH: -10 });
  const [newPage, setPage] = useState(1);

  const pageParams = {
    p: newPage,
  };
  const qParams = Object.fromEntries(queryParams);
  const mergedParams = { ...qParams, ...pageParams };
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `liquidations/liquidation-simulator/`,
    mergedParams,
    {
      keepPreviousData: true,
    }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const {
    total_stats: totals,
    assets,
    collateral_liquidations: collateralLiquidations,
    debt_liquidations: debtLiquidations,
    wallet_liquidations: walletLiquidations,
  } = data;

  const assetData = [];

  for (const [key, value] of queryParams) {
    const asset = assets.find((asset) => asset.underlying_symbol === key);
    if (asset) {
      const assetObject = {
        key,
        value: Number(value),
        price: asset.underlying_price,
      };
      assetData.push(assetObject);
    } else {
    }
  }

  const totalStats = [
    {
      title: "Wallets at risk",
      normalValue: <Value value={totals.liquidations_count} decimals={0} />,
    },
    {
      title: "Collateral at risk",
      normalValue: (
        <Value
          value={totals.liquidated_collateral_usd}
          decimals={2}
          prefix="$"
          compact
        />
      ),
    },
    {
      title: "Repayable debt",
      normalValue: (
        <Value value={totals.liquidated_debt_usd} decimals={2} prefix="$" compact />
      ),
    },
  ];

  const onSubmit = (assetValues) => {
    const updatedParams = {};
    assetValues.forEach((item) => {
      updatedParams[item.key] = item.value;
    });

    setQueryParams(updatedParams, true);
  };

  return (
    <>
      <h1 className="mb-3 h2 text-center">Liquidation Simulator</h1>
      <div className="mb-2 text-white">
        To add assets, click the 'Add Row' button and choose a price change for each
        asset. Price changes can range from -99 to 100. Once you are satisfied with your
        chosen parameters, click 'Apply.' The simulator assumes an instantaneous price
        change and provides a table of liquidations that would trigger under these
        conditions.{" "}
      </div>
      <Row className="mb-5">
        <AssetForm assets={assets} defaults={assetData} onSubmit={onSubmit} />
      </Row>
      <StatsBar stats={totalStats} className="mb-5" />

      {totals.liquidations_count > 0 ? (
        <>
          <Row className="mb-5">
            <Col lg={6}>
              <AggregatedAtRiskChart
                data={collateralLiquidations}
                type={"collateral"}
              />
            </Col>
            <Col lg={6}>
              <AggregatedAtRiskChart data={debtLiquidations} type="debt" />
            </Col>
          </Row>
          <AtRiskPositionsTable
            data={walletLiquidations}
            count={totals.liquidations_count}
            page={newPage}
            setPage={setPage}
          />
        </>
      ) : (
        <div className="text-center mb-5 text-white">
          There are no wallets at risk with the selected parameters.
        </div>
      )}
    </>
  );
}

export default withErrorBoundary(LiquidationSimulator);

import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";
import _ from "lodash";
function CryptoIcon(props) {
  const { name, size, address, showTooltip = true, ...rest } = props;
  let updatedName = name;

  if (name === "ethereum") {
    updatedName = "eth";
  } else if (name === "gnosis") {
    updatedName = "gno";
  }
  const [imgSrc, setImgSrc] = useState(
    `https://icons.blockanalitica.com/currency/${(updatedName || "").toLowerCase()}.svg`
  );
  const idRef = useRef(`icon-${_.uniqueId()}`);
  return (
    <>
      <img
        {...rest}
        id={idRef.current}
        src={imgSrc}
        alt={updatedName}
        style={{ width: size, height: size }}
        onError={() => {
          setImgSrc("/assets/images/icon/missing-currency.svg");
        }}
      />
      {showTooltip && (
        <UncontrolledTooltip placement="bottom" target={idRef.current}>
          {updatedName}
        </UncontrolledTooltip>
      )}
    </>
  );
}

CryptoIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
};

CryptoIcon.defaultProps = {
  size: "1rem",
};

export default CryptoIcon;

import React, { useState } from "react";
import { Col, Row, Input, Button, InputGroup } from "reactstrap";
import makeBlockie from "ethereum-blockies-base64";
import SearchInput from "../../../components/SearchInput/SearchInput.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Loader from "../../../components/Loader/Loader.js";
import Value from "../../../components/Value/Value.js";
import Address from "../../../components/Address/Address.js";
import ExternalLink from "../../../components/ExternalLink/ExternalLink.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch, useQueryParams, useSmartNavigate } from "../../../hooks.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import TitleWithTooltip from "../../../components/TitleWithTooltip/TitleWithTooltip.js";
function LeaderBoardTable(props) {
  const smartNavigate = useSmartNavigate();
  const [fullyDilutedMarketCap, setFullyDilutedMarketCap] = useState(0);
  const [sparkTokenValue, setSparkTokenValue] = useState(0);
  const queryParams = useQueryParams();
  const order = queryParams.get("order") || "-airdrop_total_share";
  const searchText = queryParams.get("search");
  const pageSize = 25;
  const page = parseInt(queryParams.get("page")) || 1;
  const parseValue = (input) => {
    const regex = /^(\d+(?:\.\d+)?)(k|m|b)?$/i;
    const match = input.toLowerCase().match(regex);
    if (match) {
      const value = parseFloat(match[1]);
      const suffix = match[2];
      switch (suffix) {
        case "k":
          return value * 1e3;
        case "m":
          return value * 1e6;
        case "b":
          return value * 1e9;
        default:
          return value;
      }
    }
    return NaN; // Return NaN if the format is incorrect
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    // Allow numbers, decimal points, and specific suffixes (k, m, b)
    const validInput = input.replace(/[^0-9.kmbKMB]/g, "");
    setFullyDilutedMarketCap(validInput);
  };
  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    "airdrop/leaderboard/",
    {
      p: page,
      p_size: pageSize,
      order,
      search: searchText,
      skpToken: sparkTokenValue,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }
  const handleSubmit = () => {
    const numericValue = parseValue(fullyDilutedMarketCap);
    if (!isNaN(numericValue)) {
      const value = numericValue / 1_500_000_000;
      setSparkTokenValue(value.toFixed(2));
    } else {
      setSparkTokenValue(0);
    }
  };
  const onRowClick = (e, row) => {
    smartNavigate(e, `wallets/${row.wallet_address}/`);
  };
  const { results } = data;
  const forumLink = (
    <>
      <ExternalLink href="https://docs.spark.fi/governance/spk-token">
        SPK Token & Airdrop FAQ page
      </ExternalLink>
    </>
  );

  return (
    <>
      <div className="mb-4 text-grey">
        Welcome to the Spark Airdrop Leaderboard! The first season of the Spark
        Pre-Farming Airdrop concluded with block number 19911718, marking the start of
        Season 2 with the subsequent block. The total allocation for Season 1 was
        increased from 30m to 60m SPK tokens. In Season 2, a total of 6.66 million
        tokens will be emitted monthly, distributed equally across each block. For more
        information, please visit the {forumLink}. Keep an eye out for updates as the
        airdrop continues.
      </div>
      <Row className="mb-4">
        <Col>
          <ToolkitProvider
            bootstrap4
            search
            keyField="wallet_address"
            data={results}
            columns={[
              {
                dataField: "",
                text: "",
                formatter: (_, row) => {
                  const blockie = makeBlockie(row.wallet_address);
                  return <img src={blockie} alt={row.wallet_address} />;
                },
                classes: "blockie",
              },
              {
                dataField: "wallet_address",
                text: "Address",
                formatter: (cell) => <Address value={cell} short />,
              },
              {
                dataField: "token_reward_s1",
                text: "season 1",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell} decimals={2} suffix="SPK" compact dashIfZero />
                    <br />
                    <Value
                      value={row.token_reward_s1_usd}
                      decimals={2}
                      prefix={"$"}
                      hideIfZero
                      compact
                      small
                      className="gray"
                    />
                  </>
                ),
                sort: true,
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "token_reward_s2",
                text: "season 2",
                formatter: (cell, row) => (
                  <>
                    <Value
                      value={cell}
                      decimals={2}
                      suffix={"SPK"}
                      hideIfZero
                      compact
                    />
                    <br />
                    <Value
                      value={row.token_reward_s2_usd}
                      decimals={2}
                      prefix={"$"}
                      compact
                      small
                      className="gray"
                      hideIfZero
                    />
                  </>
                ),
                sort: true,
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "token_reward_total",
                text: "current total",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell} decimals={2} suffix={"SPK"} compact />
                    <br />
                    <Value
                      value={row.token_reward_total_usd}
                      decimals={2}
                      prefix={"$"}
                      hideIfZero
                      compact
                      small
                      className="gray"
                    />
                  </>
                ),
                sort: true,
                headerAlign: "right",
                align: "right",
              },
            ]}
            bordered={false}
          >
            {(props) => (
              <div>
                <div className="react-bootstrap-table-search  justify-content-between d-flex align-items-center flex-wrap">
                  <div className="mb-3">
                    <label>
                      <TitleWithTooltip
                        title="Year 4 SPK Market Cap"
                        tooltipValue={`Enter the projected market capitalization for the Spark (SPK) token in its fourth year to calculate the price 
                        per SPK token. A total of 1.5 billion SPK tokens will be issued by this time. 
                        The SPK token values shown in the airdrop recipients' table will be determined based on the market cap you provide.`}
                      />
                    </label>
                    <InputGroup>
                      <Input
                        id="fullyDilutedMarketCap"
                        type="text"
                        value={`$${fullyDilutedMarketCap}`} // Display with dollar sign
                        onChange={handleInputChange}
                        placeholder="Enter market cap"
                      />
                      <Button color="secondary" onClick={handleSubmit} className="ml-2">
                        Calculate
                      </Button>
                    </InputGroup>
                  </div>

                  {/* Display the calculated Spark token value */}
                  {sparkTokenValue !== null && (
                    <div className="spark-token-value">
                      <strong>SPK token valuation:</strong> ${sparkTokenValue}
                    </div>
                  )}
                  <div>
                    <SearchInput
                      placeholder="Search by address..."
                      initialSearchText={searchText}
                      {...props.searchProps}
                    />
                  </div>
                </div>
                <RemoteTable
                  {...props.baseProps}
                  onRowClick={onRowClick}
                  loading={isPreviousData}
                  page={page}
                  pageSize={pageSize}
                  totalPageSize={data.count}
                />
              </div>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(LeaderBoardTable);

import makeBlockie from "ethereum-blockies-base64";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Address from "../../../components/Address/Address.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import ExternalLink from "../../../components/ExternalLink/ExternalLink.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { smartLocationPrefix } from "../../../utils/url.js";
import { useLocation } from "react-router-dom";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import { parseUTCDateTime } from "../../../utils/datetime.js";
import { smartEtherscanUrl } from "../../../utils/url.js";
function LiquidationsTable(props) {
  const pageSize = 5;
  const { timePeriod } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(null);
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  const etherscanUrl = smartEtherscanUrl(location);

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `liquidations/`,
    {
      p: page,
      p_size: pageSize,
      order,
      days_ago: timePeriod,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  return (
    <RemoteTable
      hover={false}
      loading={isPreviousData}
      keyField="order_index"
      data={data.results}
      columns={[
        {
          dataField: "",
          text: "",
          formatter: (_, row) => {
            const blockie = makeBlockie(row.wallet_address);
            return <img src={blockie} alt={row.wallet_address} />;
          },
          classes: "blockie",
        },
        {
          dataField: "wallet_address",
          text: "Wallet address",
          formatter: (cell, row) => (
            <Link to={`${prefix}wallets/${cell}/`} key={cell}>
              <Address value={cell} short />
            </Link>
          ),
        },
        {
          dataField: "collateral_seized_usd",
          text: "collateral liquidated",
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) => (
            <div className="d-flex align-items-center">
              <CryptoIcon
                name={row.collateral_underlying_symbol}
                size="2em"
                className="me-3"
              />
              <div>
                <Value
                  value={row.collateral_seized}
                  decimals={2}
                  suffix={row.collateral_underlying_symbol}
                  compact
                />
                <br />
                <Value
                  value={cell}
                  decimals={2}
                  prefix="$"
                  className="text-small gray"
                  compact
                />
              </div>
            </div>
          ),
        },

        {
          dataField: "debt_repaid_usd",
          text: "Debt Repaid",
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) => (
            <div className="d-flex align-items-center">
              <CryptoIcon
                name={row.debt_underlying_symbol}
                size="2em"
                className="me-3"
              />
              <div>
                <Value
                  value={row.debt_repaid}
                  decimals={2}
                  suffix={row.debt_underlying_symbol}
                  compact
                />
                <br />
                <Value
                  value={cell}
                  decimals={2}
                  prefix="$"
                  className="text-small gray"
                  compact
                />
              </div>
            </div>
          ),
        },

        {
          dataField: "",
          text: "",
          formatter: (_, row) => {
            const blockie = makeBlockie(row.liquidator_address);
            return <img src={blockie} alt={row.liquidator_address} />;
          },
          classes: "blockie",
        },
        {
          dataField: "liquidator_address",
          text: "liquidator",
          headerAlign: "left",
          align: "left",
          formatter: (cell, row) => (
            <Link to={`${prefix}liquidators/${cell}/`} key={cell}>
              <Address value={cell} short />
            </Link>
          ),
        },
        {
          dataField: "",
          text: "liquidation bonus",
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) => (
            <Value
              value={row.collateral_seized_usd - row.debt_repaid_usd}
              decimals={2}
              prefix="$"
              compact
            />
          ),
        },
        {
          dataField: "datetime",
          text: "Date",
          sort: true,
          formatter: (cell, row) => (
            <>
              <DateTimeAgo dateTime={parseUTCDateTime(cell)} />
              <br />
              <span className="gray text-small">
                {row.block_number}
                <ExternalLink
                  href={`${etherscanUrl}tx/${row.tx_hash}`}
                  className="ms-2 gray"
                />
              </span>
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
      ]}
      page={page}
      pageSize={pageSize}
      totalPageSize={data.count}
      onSortChange={setOrder}
      onPageChange={setPage}
    />
  );
}

export default withErrorBoundary(LiquidationsTable);

import React from "react";
import { useFetch } from "../../../hooks";
import LoadingCard from "../../../components/LoadingCard/LoadingCard";
import Graph from "../../../components/Graph/Graph.js";
import { SYMBOLS_PALETTE } from "../../../utils/colors.js";
import { pieGraphDataLimiter, pieTooltipLabelNumber } from "../../../utils/graph.js";

function DoughnutData({ data, type }) {
  let valueKey = type === "supply" ? "total_supply_usd" : "total_borrow_usd";
  const { series, labels } = pieGraphDataLimiter(data, "symbol", valueKey, 0);

  const backgroundColors = labels.map((label) => SYMBOLS_PALETTE[label] || "#D2691E");
  const borderColors = labels.map((label) => SYMBOLS_PALETTE[label] || "#D2691E");

  const newSeries = [
    {
      data: series[0]["data"],
      backgroundColor: backgroundColors,
      borderColor: borderColors,
    },
  ];

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return pieTooltipLabelNumber(context, "$", "");
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    cutout: "42%",
    layout: {
      padding: 0,
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return <Graph type="pie" series={newSeries} labels={labels} options={options} />;
}

function MarketCompositionChart(props) {
  const { type, daysAgo } = props;
  const { data, isLoading, isError } = useFetch("markets/composition", {
    days_ago: daysAgo,
  });

  return (
    <LoadingCard
      title={`${type} composition`}
      isError={isError}
      isLoading={isLoading}
      data={data}
    >
      <DoughnutData data={data} type={type} />
    </LoadingCard>
  );
}

export default MarketCompositionChart;

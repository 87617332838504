import React, { useState } from "react";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import _ from "lodash";
import GraphSwitch from "../../../components/GraphSwitch/GraphSwitch.js";
import { SYMBOLS_PALETTE, DEFAULT_PALETTE } from "../../../utils/colors.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import Loader from "../../../components/Loader/Loader.js";
function TreasuryIncomeChart(props) {
  const [graphCurrency, setGraphCurrency] = useState("USD");
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `treasury/accrued-income/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }
  const results = data.results;
  const grouped = _.groupBy(results, "reserve");

  const currencyOptions = [
    { key: "USD", value: "$" },
    {
      key: "asset",
      value: "Token",
    },
  ];

  const series = [];

  let fakeIndex = 0;
  Object.entries(grouped).forEach(([key, rows]) => {
    let color = SYMBOLS_PALETTE[key] || DEFAULT_PALETTE[fakeIndex];
    let item = {
      label: key,
      data: rows.map((row) => ({
        x: row["date"],
        y:
          graphCurrency === "asset" ? row["accrued_income"] : row["accrued_income_usd"],
      })),
      color,
      borderColor: color,
    };

    series.push(item);
    fakeIndex = fakeIndex + 1;
  });

  let prefix = "";

  let fill_option = false;
  let stacked_option = false;
  if (graphCurrency === "USD") {
    prefix = "$";
    fill_option = true;
    stacked_option = true;
  }

  const options = {
    maintainAspectRatio: false,
    orderSeries: true,
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "x",
    },
    fill: fill_option,
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: stacked_option,
        ticks: {
          callback: (value) => {
            if (graphCurrency === "USD") {
              return prefix + compact(value, 2, true);
            } else {
              return compact(value, 2, true);
            }
          },
          beginAtZero: true,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, prefix);
          },
          footer: (tooltipItems) => {
            if (graphCurrency === "USD") {
              const total = tooltipItems.reduce(
                (total, tooltip) => total + tooltip.parsed.y,
                0
              );
              return `Total: $` + compact(total, 2, true);
            }
          },
        },
      },
    },
  };

  return (
    <LoadingCard
      title="TOKENS IN TREASURY"
      minHeight
      isError={isError}
      isLoading={isLoading}
      data={data}
    >
      <div className="d-flex justify-content-end">
        <GraphSwitch
          label="Currency:"
          options={currencyOptions}
          onChange={(option) => setGraphCurrency(option)}
        />
      </div>
      <div>
        <Graph
          style={{ width: "100%", height: "33.33vh" }}
          series={series}
          options={options}
        />
      </div>
    </LoadingCard>
  );
}

export default withErrorBoundary(TreasuryIncomeChart);

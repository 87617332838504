import makeBlockie from "ethereum-blockies-base64";
import React, { useEffect, useState } from "react";
import Address from "../../../components/Address/Address.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Value from "../../../components/Value/Value.js";
import { useFetch, useSmartNavigate } from "../../../hooks";
import { parseUTCDateTime } from "../../../utils/datetime.js";

function AtRiskPositionsTable(props) {
  const navigate = useSmartNavigate();
  const { address, price_change } = props;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("ABS(price_change)");

  useEffect(() => {
    setPage(1);
  }, [price_change]);

  let pageSize;
  if (address) {
    pageSize = 10;
  } else {
    pageSize = 25;
  }
  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    "at-risk/positions/",
    {
      price_change,
      address,
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const onRowClick = (e, row) => {
    navigate(e, `wallets/${row.address}/`);
  };

  let change;

  return (
    <>
      {data.results.length > 0 ? (
        <RemoteTable
          loading={isPreviousData}
          keyField="id"
          data={data.results}
          onRowClick={onRowClick}
          columns={[
            {
              dataField: "",
              text: "",
              formatter: (_, row) => {
                const blockie = makeBlockie(row.address);
                return <img src={blockie} alt={row.address} />;
              },
              classes: "blockie",
            },
            {
              dataField: "address",
              text: "Wallet address",
              formatter: (cell, row) => <Address value={cell} short />,
            },
            {
              dataField: "price_change",
              text: "Price change",
              sort: true,
              formatter: (cell) => <Value value={cell} decimals={0} suffix="%" />,
              headerAlign: "left",
              align: "left",
            },
            {
              dataField: "collateral_amount_usd",
              text: "collateral liquidated",
              sort: true,
              formatter: (cell, row) => (
                <>
                  <div className="d-flex align-items-center">
                    <CryptoIcon
                      name={row.collateral_symbol}
                      size="2em"
                      className="me-3"
                    />
                    <div>
                      <Value
                        value={row.collateral_amount}
                        decimals={2}
                        suffix={row.collateral_symbol}
                        compact
                      />
                      <br />
                      <Value
                        value={cell}
                        decimals={2}
                        className="text-small gray"
                        prefix="$"
                        compact
                      />
                    </div>
                  </div>
                </>
              ),
            },
            {
              dataField: "price_change",
              text: "liquidaton price",
              sort: false,
              formatter: (cell, row) => (
                <Value
                  value={row.price_change > 0 ? row.debt_price : row.collateral_price}
                  decimals={2}
                  prefix="$"
                  suffix={
                    row.price_change > 0 ? row.debt_symbol : row.collateral_symbol
                  }
                  compact100k
                />
              ),
              headerAlign: "left",
              align: "left",
            },
            {
              dataField: "debt_amount_usd",
              text: "Debt Repaid",
              headerAlign: "left",
              align: "left",
              sort: true,
              formatter: (cell, row) => (
                <>
                  <div className="d-flex align-items-center">
                    <CryptoIcon name={row.debt_symbol} size="2em" className="me-3" />
                    <div>
                      <Value
                        value={row.debt_amount}
                        decimals={2}
                        suffix={row.debt_symbol}
                        compact
                      />
                      <br />
                      <Value
                        value={cell}
                        decimals={2}
                        className="text-small gray"
                        prefix="$"
                        compact
                      />
                    </div>
                  </div>
                </>
              ),
            },
            {
              dataField: "incentive",
              text: "incentive",
              sort: true,
              formatter: (cell, row) => (
                <Value value={cell} decimals={2} prefix="$" compact100k />
              ),
              headerAlign: "left",
              align: "left",
            },
            {
              dataField: "last_activity",
              text: "last activity",
              sort: false,
              formatter: (cell, row) => (
                <DateTimeAgo dateTime={parseUTCDateTime(cell)} inDays />
              ),
              headerAlign: "left",
              align: "left",
            },
          ]}
          page={page}
          pageSize={pageSize}
          totalPageSize={data.count}
          onPageChange={setPage}
          onSortChange={setOrder}
        />
      ) : (
        <div className="text-center mt-4 gray">
          No wallets at risk of being liquidated at {price_change}% general market price
          {change}
        </div>
      )}
    </>
  );
}

export default AtRiskPositionsTable;

export const SYMBOLS_PALETTE = {
  WETH: "#959190",
  ETH: "#959190",
  USDC: "#3e73c4",
  WBTC: "#ef8e19",
  Others: "#808080",
  others: "#808080",
  sDAI: "#8BC34A",
  DAI: "#F4B731",
  WXDAI: "#F4B731",
  wstETH: "#03a9f4",
  GNO: "#00CEC4",
  wstETHe: "#C4DFE6",
  rETH: "#f27852",
  USDT: "#26A17B",
};

export const DEFAULT_PALETTE = [
  "#ffc107",
  "#9669ed",
  "#03A9F4",
  "#FF4560",
  "#775DD0",
  "#3F51B5",
  "#4CAF50",
  "#F9CE1D",
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#0276aa",
  "#e20020",
  "#492fa3",
  "#2c387e",
  "#357a38",
  "#bd9905",
  "#0064af",
  "#009e69",
  "#c28000",
  "#014361",
  "#810012",
  "#2a1b5d",
  "#192048",
  "#1e4620",
  "#6c5702",
  "#003964",
  "#005a3c",
  "#6f4900",
  "#22b8fc",
  "#ff6077",
  "#8b75d7",
  "#5767c4",
  "#65bc69",
  "#f9d53e",
];

export const PROTECTION_SCORE_PALETTE = {
  low: "#27ae69",
  medium: "#ffc107",
  high: "#ff371d",
};

import React, { useState } from "react";
import { Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import {
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";

import { faMedium, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import NetworkSelector from "../../components/NetworkSelector/NetworkSelector.js";
import SimpleRedirect from "../../components/SimpleRedirect/SimpleRedirect.js";
import { ReactComponent as BALogoWhite } from "../../images/BA-logo-full-white.svg";
import logo from "../../images/spark-logo.svg";
import SDAI from "../../pages/SDAI/SDAI.js";
import LiquidationSimulator from "../../pages/simulator/LiquidationSimulator.js";
import Activity from "../../pages/activity/Activity.js";
import AtRisk from "../../pages/at-risk/AtRisk";
import ErrorPage from "../../pages/error/ErrorPage.js";
import Homepage from "../../pages/homepage/Homepage.js";
import Liquidations from "../../pages/liquidations/Liquidations.js";
import Liquidator from "../../pages/liquidations/Liquidator";
import Market from "../../pages/market/Market.js";
import Markets from "../../pages/markets/Markets";
import Parameters from "../../pages/parameters/Parameters";
import Wallet from "../../pages/wallets/Wallet.js";
import Wallets from "../../pages/wallets/Wallets.js";
import { smartLocationPrefix } from "../../utils/url";
import ExternalLink from "../ExternalLink/ExternalLink.js";
import styles from "./Layout.module.scss";
import Treasury from "../../pages/treasury/Treasury.js";
import Airdrop from "../../pages/airdrop/Airdrop.js";

function Layout(props) {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const toggleNavbar = () => setIsNavbarOpen(!isNavbarOpen);
  const location = useLocation();
  const prefix = smartLocationPrefix(location);

  const v1EthereumRoutes = [
    { path: "/", element: <Homepage /> },
    { path: "wallets/", element: <Wallets /> },
    { path: "wallets/:address/", element: <Wallet /> },
    { path: "markets/", element: <Markets /> },
    { path: "parameters/", element: <Parameters /> },
    { path: "markets/:symbol/:segment?", element: <Market /> },
    { path: "liquidations/:segment?", element: <Liquidations /> },
    { path: "liquidators/:address/", element: <Liquidator /> },
    { path: "liquidation-simulator/", element: <LiquidationSimulator /> },
    { path: "activity/", element: <Activity /> },
    { path: "at-risk/", element: <AtRisk /> },
    { path: "sDAI/", element: <SDAI /> },
    { path: "treasury/", element: <Treasury /> },
    { path: "airdrop/", element: <Airdrop /> },
  ];

  const v1GnosisRoutes = [
    { path: "/", element: <Homepage /> },
    { path: "wallets/", element: <Wallets /> },
    { path: "wallets/:address/", element: <Wallet /> },
    { path: "markets/", element: <Markets /> },
    { path: "parameters/", element: <Parameters /> },
    { path: "markets/:symbol/:segment?", element: <Market /> },
    { path: "liquidations/:segment?", element: <Liquidations /> },
    { path: "liquidators/:address/", element: <Liquidator /> },
    { path: "liquidation-simulator/", element: <LiquidationSimulator /> },
    { path: "activity/", element: <Activity /> },
    { path: "at-risk/", element: <AtRisk /> },
    { path: "sDAI/", element: <SDAI /> },
    { path: "treasury/", element: <Treasury /> },
    { path: "airdrop/", element: <Airdrop /> },
  ];

  return (
    <>
      <Container>
        <header className="mb-4">
          <Navbar expand="md" className="fw-bolder" dark container={false}>
            <NavbarBrand className={styles.navbarBrand} tag={Link} to={`${prefix}`}>
              <img className={styles.logo} src={logo} alt="Spark" />
            </NavbarBrand>

            <Collapse isOpen={isNavbarOpen} navbar>
              <Nav className="flex-grow-1 justify-content-right" navbar>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}`}
                    active={location.pathname === "/"}
                  >
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}markets/`}
                    active={location.pathname.startsWith("/markets")}
                  >
                    Markets
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}wallets/`}
                    active={location.pathname.startsWith("/wallets")}
                  >
                    Wallets
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}at-risk/`}
                    active={location.pathname.startsWith("/at-risk")}
                  >
                    Wallets at risk
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}liquidations/`}
                    active={location.pathname.startsWith("/liquidations")}
                  >
                    Liquidations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}activity/`}
                    active={location.pathname.startsWith("/activity")}
                  >
                    Activity
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}parameters/`}
                    active={location.pathname.startsWith("/parameters")}
                  >
                    Parameters
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}sdai/`}
                    active={location.pathname.startsWith("/sdai")}
                  >
                    sDAI
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={styles.navLink}
                    tag={Link}
                    to={`${prefix}treasury/`}
                    active={location.pathname.startsWith("/treasury")}
                  >
                    Treasury
                  </NavLink>
                </NavItem>
                {prefix === "/v1/ethereum/" && (
                  <NavItem>
                    <NavLink
                      className={styles.navLink}
                      tag={Link}
                      to={`${prefix}airdrop/`}
                      active={location.pathname.startsWith("/airdrop")}
                    >
                      Airdrop
                    </NavLink>
                  </NavItem>
                )}
                <NavItem className="ms-auto d-flex align-items-center">
                  <div className="me-3">
                    <NetworkSelector key={location.pathname} />
                  </div>

                  <ExternalLink
                    href="https://app.sparkprotocol.io/"
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <span>
                      Spark App{" "}
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" />
                    </span>
                  </ExternalLink>
                </NavItem>
              </Nav>
            </Collapse>
            <NavbarToggler onClick={toggleNavbar} />
          </Navbar>
        </header>

        <main>
          <Routes>
            {/* Redirect from root */}
            <Route path="/" element={<SimpleRedirect to="/v1/ethereum/" />} />

            {/* V1 Ethereum */}
            {v1EthereumRoutes.map((route) => {
              const path = `v1/ethereum/${route.path}`;
              return <Route key={path} path={path} element={route.element} />;
            })}

            {/* V1 Gnosis */}
            {v1GnosisRoutes.map((route) => {
              const path = `v1/gnosis/${route.path}`;
              return <Route key={path} path={path} element={route.element} />;
            })}

            {/* Catch all */}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </main>

        <footer className="mt-4 text-center p-3">
          <hr className="mb-4 w-100" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center text-muted gap-2">
              <Link href="https://blockanalitica.com" target="_blank">
                <ExternalLink href="https://blockanalitica.com" className="gray">
                  <BALogoWhite height={60} />
                </ExternalLink>
              </Link>
              <span>|</span>
              <span>© 2023. All rights reserved.</span>
            </div>

            <div className="d-flex">
              <ExternalLink href="https://twitter.com/BlockAnalitica" className="gray">
                <FontAwesomeIcon icon={faTwitter} />
              </ExternalLink>
              <ExternalLink
                href="https://medium.com/block-analitica"
                className="gray ms-3"
              >
                <FontAwesomeIcon icon={faMedium} />
              </ExternalLink>
            </div>
          </div>
        </footer>
      </Container>
    </>
  );
}

export default Layout;

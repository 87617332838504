import PropTypes from "prop-types";
import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import {
  tooltipFooterTotal,
  tooltipLabelNumber,
  tooltipTitleDateTime,
} from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { DateTime } from "luxon";
function LiquidationsBarChart(props) {
  const { results, timePeriod, isError, isLoading } = props;

  let xUnit = "day";
  if (timePeriod > 180) {
    xUnit = "month";
  } else if (timePeriod > 30) {
    xUnit = "week";
  }
  const series = [];
  Object.entries(results).forEach(([key, rows]) => {
    series.push({
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row["datetime"],
        y: row["collateral_seized_usd"],
      })),
    });
  });

  const now = DateTime.now().toMillis();
  const startDate = new Date(now - timePeriod * 24 * 60 * 60 * 1000);

  const options = {
    aspectRatio: 1.5,
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: xUnit,
          displayFormats: {
            week: "W yyyy",
          },
        },
        min: new Date(startDate),
        max: new Date(now),
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            let format = "LLL yyyy";
            let prefix = "";
            if (xUnit === "week") {
              prefix = "Week starting on ";
              format = null;
            } else if (xUnit === "day") {
              format = "MMM d, yyyy";
            }

            return prefix + tooltipTitleDateTime(tooltipItems, true, false, format);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, "$");
          },
          footer: (tooltipItems) => {
            return tooltipFooterTotal(tooltipItems, "Total: $");
          },
        },
      },
    },
  };

  return (
    <LoadingCard
      minHeight
      title={"liquidated collateral historic"}
      data={results}
      isError={isError}
      isLoading={isLoading}
    >
      <Graph series={series} options={options} type="bar" />
    </LoadingCard>
  );
}

LiquidationsBarChart.propTypes = {
  period: PropTypes.string,
};

export default withErrorBoundary(LiquidationsBarChart);

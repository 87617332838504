import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import LiquidatorStats from "./components/LiquidatorStats.js";
import CryptoIcon from "../../components/CryptoIcon/CryptoIcon.js";
import ExternalLink from "../../components/ExternalLink/ExternalLink.js";
import classnames from "classnames";
import makeBlockie from "ethereum-blockies-base64";
import Address from "../../components/Address/Address.js";
import styles from "./Liquidator.module.scss";
import CopyToClipboard from "../../components/CopyToClipboard/CopyToClipboard.js";
import LiquidatorTable from "./components/LiquidatorTable.js";
import { useLocation } from "react-router-dom";
import { smartEtherscanUrl } from "../../utils/url.js";

function Liquidator(props) {
  let params = useParams();
  const location = useLocation();
  const { address } = useParams();
  const etherscanUrl = smartEtherscanUrl(location);
  const blockie = makeBlockie(address);
  return (
    <>
      <div className="d-flex justify-content-center my-5">
        <img
          className={classnames(styles.roundedCircle, styles.LiquidatorLogo, "me-3")}
          src={blockie}
          alt={address}
        />
        <div>
          <h1 className="h2 m-0 p-0">
            <Address value={address} short />
            <CopyToClipboard text={address} className="ms-2 small" />
          </h1>
          <div className="d-flex align-items-center">
            <ExternalLink href={`${etherscanUrl}address/${address}`} className="me-2">
              <CryptoIcon name="etherscan" />
            </ExternalLink>
            <ExternalLink
              href={`https://debank.com/profile/${address}`}
              className="me-2"
            >
              <CryptoIcon name="debank" />
            </ExternalLink>
            <ExternalLink href={`https://zapper.fi/account/${address}`}>
              <CryptoIcon name="zapper" />
            </ExternalLink>
          </div>
        </div>
      </div>

      <Row>
        <Col xl={12} className="mb-5">
          <LiquidatorStats address={params.address} />
        </Col>
        <Col xl={12} className="mb-5">
          <LiquidatorTable address={params.address} />
        </Col>
      </Row>
    </>
  );
}

export default Liquidator;

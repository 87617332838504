import React from "react";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import StatsBar from "../../../components/Stats/StatsBar.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { useFetch } from "../../../hooks";
import { useParams } from "react-router-dom";
import TitleWithTooltip from "../../../components/TitleWithTooltip/TitleWithTooltip.js";
import Loader from "../../../components/Loader/Loader.js";
function AssetAsDebtlInfo(props) {
  const { symbol } = useParams();
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/debt/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const debtStats = [
    {
      title: (
        <TitleWithTooltip
          title="Debt collateralization"
          tooltipValue={`Total amount of collateral backing ${symbol} debt.`}
        />
      ),
      bigValue: (
        <Value
          value={data.total}
          decimals={2}
          prefix="$"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <ValueChange
          value={0}
          decimals={2}
          suffix=""
          hideIfZero
          tooltipValue={0}
          className="text-small"
        />
      ),
    },
    {
      title: "total debt",
      bigValue: (
        <Value
          value={data.total_debt}
          decimals={2}
          prefix="$"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <ValueChange
          value={0}
          decimals={2}
          suffix=""
          hideIfZero
          tooltipValue={0}
          className="text-small"
        />
      ),
    },
    {
      title: (
        <TitleWithTooltip
          title="Debt utilization"
          tooltipValue={`Current debt divided by maximum debt currently collateralized.`}
        />
      ),
      normalValue: (
        <Value
          value={data.utilization}
          decimals={2}
          suffix="%"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <ValueChange
          value={0}
          decimals={2}
          suffix=""
          hideIfZero
          tooltipValue={0}
          className="text-small"
        />
      ),
    },
    {
      title: (
        <TitleWithTooltip
          title="Liquidation buffer"
          tooltipValue={`Debt that can be drawn untill liquidation threshold is reached.`}
        />
      ),
      bigValue: (
        <Value
          value={data.available}
          decimals={2}
          prefix="$"
          className="text-big"
          compact
        />
      ),
      smallValue: (
        <ValueChange
          value={0}
          decimals={2}
          suffix=""
          hideIfZero
          tooltipValue={0}
          className="text-small"
        />
      ),
    },
  ];
  return <StatsBar stats={debtStats} />;
}

export default withErrorBoundary(AssetAsDebtlInfo);

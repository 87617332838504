import _ from "lodash";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import Loader from "../../../components/Loader/Loader.js";
import { DateTime } from "luxon";

function GraphComponent({ data }) {
  const grouped = _.groupBy(data, "symbol");
  const series = [];
  const now = DateTime.now().toMillis();
  const startDate = new Date(now - 31 * 24 * 60 * 60 * 1000);
  Object.entries(grouped).forEach(([key, rows]) => {
    let item = {
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row.datetime,
        y: row.debt_amount,
      })),
    };
    series.push(item);
  });

  const options = {
    orderSeries: true,
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "x",
    },
    fill: true,
    scales: {
      x: {
        stacked: true,
        type: "time",
        time: {
          unit: "day",
        },
        min: new Date(startDate),
        max: new Date(now),
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, "$");
          },
          footer: (tooltipItems) => {
            const total = tooltipItems.reduce(
              (total, tooltip) => total + tooltip.parsed.y,
              0
            );
            return `Total: $` + compact(total, 2, true);
          },
        },
      },
    },
  };

  return <Graph series={series} options={options} type="bar" />;
}

function AssetAsCollateralHistory(props) {
  const { symbol } = props;
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/collateral-backed-history/`
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  } else if (data.length === 0) {
    return <></>;
  }
  return (
    <LoadingCard
      isError={isError}
      isLoading={isLoading}
      data={data}
      title="historical liabilities"
    >
      <GraphComponent data={data} />
    </LoadingCard>
  );
}

export default withErrorBoundary(AssetAsCollateralHistory);

import _ from "lodash";
import React, { useState } from "react";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Graph from "../../../components/Graph/Graph.js";
import GraphSwitch from "../../../components/GraphSwitch/GraphSwitch.js";
import { withErrorBoundary } from "../../../hoc.js";
import { tooltipLabelNumber } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function CollateralAtRiskChart(props) {
  const { data, type, symbol, chartType } = props;
  const [graphCurrency, setGraphCurrency] = useState("asset");
  const priceChange = type === "long" ? "price decrease" : "price increase";
  const grouped = _.groupBy(data, "underlying_symbol");
  const series = [];

  let value;
  if (chartType === "bar") {
    value = graphCurrency === "USD" ? "amount_usd" : "amount";
  } else {
    value = graphCurrency === "USD" ? "total_amount_usd" : "total_amount";
  }

  let prefix = "";
  let suffix = "";
  let text = "USD";

  prefix = graphCurrency === "USD" ? "$" : "";
  suffix = graphCurrency === "USD" ? "" : " " + symbol;
  text = graphCurrency === "USD" ? "USD" : "";

  Object.entries(grouped).forEach(([key, rows]) => {
    let item = {
      label: key,
      symbol: key,
      data: rows.map((row) => ({
        x: row["price_change"],
        y: row[value],
      })),
    };
    series.push(item);
  });

  const currencyOptions = [
    { key: "asset", value: <CryptoIcon name={symbol} /> },
    { key: "USD", value: "$" },
  ];

  const graphSwitch =
    symbol !== undefined ? (
      <div className="d-flex justify-content-end">
        <GraphSwitch
          label="Currency:"
          options={currencyOptions}
          onChange={(option) => setGraphCurrency(option)}
        />
      </div>
    ) : null;

  const options = {
    orderSeries: true,
    fill: true,
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        stacked: true,
        reverse: type === "short" ? false : true,
        type: "linear",
        ticks: {
          callback: (value) => `${value}%`,
        },
        title: {
          display: true,
          text: priceChange,
        },
      },
      y: {
        stacked: true,
        ticks: {
          callback: (value) => prefix + compact(value, 4, true),
        },
        title: {
          display: true,
          text: `collateral ${text} amount at risk`,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: symbol !== undefined ? false : true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return `At ${tooltipItems[0].parsed.x}% markets ${priceChange}`;
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, prefix);
          },
          footer: (tooltipItems) => {
            const total = tooltipItems.reduce(
              (total, tooltip) => total + tooltip.parsed.y,
              0
            );
            const formattedValue = compact(total, 2, true);
            return `Total: ${prefix}${formattedValue}${suffix}`;
          },
        },
      },
    },
  };

  return (
    <div>
      {graphSwitch}
      <Graph series={series} options={options} type={chartType} />
    </div>
  );
}

export default withErrorBoundary(CollateralAtRiskChart);

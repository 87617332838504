import { withErrorBoundary } from "../../../hoc.js";
import { useParams } from "react-router-dom";
import AssetAsDebtlInfo from "./AssetAsDebtlInfo.js";
import AssetAsDebtSankey from "./AssetAsDebtSankey.js";
import AssetAsDebtHistory from "./AssetAsDebtHistory.js";
import AssetAsDebtUtilization from "./AssetAsDebtUtilization.js";
import AssetAsDebtComposition from "./AssetAsDebtComposition.js";
import { Col, Row } from "reactstrap";
function AssetAsDebt(props) {
  const { symbol } = useParams();

  return (
    <>
      <Row className="justify-content-center mb-5">
        <AssetAsDebtlInfo symbol={symbol} />
      </Row>
      <Row className="justify-content-center mb-5">
        <Col md={6}>
          <AssetAsDebtSankey symbol={symbol} />
        </Col>
        <Col md={6}>
          <AssetAsDebtComposition symbol={symbol} />
        </Col>
      </Row>
      <Row className="justify-content-center mb-5">
        <Col md={6}>
          <AssetAsDebtHistory symbol={symbol} />
        </Col>
        <Col md={6}>
          <AssetAsDebtUtilization symbol={symbol} />
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(AssetAsDebt);

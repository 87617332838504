import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { tooltipTitleDateTime } from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { DateTime } from "luxon";

function UtilizationChart(props) {
  const { symbol, timePeriod } = props;
  const { data, isLoading, isError } = useFetch(`markets/${symbol}/historic-details/`, {
    days_ago: timePeriod,
  });

  const results = [];
  if (data) {
    data.forEach((row) => {
      results.push({
        x: row.dt,
        y: row.utilization * 100,
      });
    });
    const now = DateTime.now();
    results[results.length - 1].x = now;
  }
  const series = [
    {
      label: "utilization",
      data: results,
    },
  ];

  const options = {
    interaction: {
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        stacked: false,
        ticks: {
          callback: (value) => compact(value, 2, true) + "%",
        },
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            const value = tooltipItem.parsed.y;
            return value !== null ? value.toFixed(2) + "%" : "";
          },
        },
      },
    },
  };

  return (
    <LoadingCard
      minHeight
      title="utilization history"
      isLoading={isLoading}
      isError={isError}
      data={data}
    >
      <Graph series={series} options={options} />
    </LoadingCard>
  );
}

export default withErrorBoundary(UtilizationChart);

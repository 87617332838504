import { withErrorBoundary } from "../../hoc.js";
import { usePageTitle } from "../../hooks";
import TreasuryIncomeChart from "./components/TreasuryIncomeChart.js";
import StatsBar from "../../components/Stats/StatsBar.js";
import Value from "../../components/Value/Value.js";
import { Row, Col } from "reactstrap";
import { useFetch } from "../../hooks.js";
import Loader from "../../components/Loader/Loader.js";
import MakerProjection from "./components/MakerProjection.js";
import AaveSection from "./components/AaveSection.js";
import TitleWithTooltip from "../../components/TitleWithTooltip/TitleWithTooltip.js";

function Treasury(props) {
  usePageTitle("Treasury");

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "treasury/current-state/"
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }
  const { results } = data;

  const statsCard = [];

  if (results.reserve_income > 0) {
    statsCard.push({
      title: "Treasury balance",
      bigValue: (
        <>
          <Value value={results.reserve_income} decimals={2} prefix="$" compact />
        </>
      ),
    });
  }

  if (results.maker_revenue > 0) {
    statsCard.push({
      title: (
        <>
          <TitleWithTooltip
            title="Current maker revenue"
            tooltipValue={`Current treasury balance + DDM revenue to date.`}
          />
        </>
      ),
      bigValue: (
        <>
          <div className="flex-column">
            <Value value={results.maker_revenue} decimals={2} prefix="$" compact />
          </div>
        </>
      ),
    });
  }

  if (results.yearly_maker_revenue > 0) {
    statsCard.push({
      title: (
        <>
          <TitleWithTooltip
            title="Yearly Maker revenue"
            tooltipValue={`Estimated yearly treasury revenue + estimated yearly DDM revenue.`}
          />
        </>
      ),
      bigValue: (
        <>
          <Value value={results.yearly_maker_revenue} decimals={2} prefix="$" compact />
        </>
      ),
    });
  }

  return (
    <>
      <div className="justify-content-center my-5 text-center">
        <h1 className="h2 m-0 p-0">Treasury</h1>
      </div>

      <StatsBar stats={statsCard} className="mb-5" />

      <TreasuryIncomeChart />
      <Row className="mb-5">
        <Col lg={6}>
          <MakerProjection results={results} />
        </Col>
        <Col lg={6}>
          <AaveSection />
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(Treasury);

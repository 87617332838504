import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { compact } from "../../../utils/number.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { tooltipLabelNumber, tooltipTitleDateTime } from "../../../utils/graph.js";
import { DateTime } from "luxon";
import _ from "lodash";

function GraphComponent({ data }) {
  const grouped = _.groupBy(data, "price_change");

  const series = [];
  const now = DateTime.now();

  Object.entries(grouped).forEach(([key, rows]) => {
    let item = {
      label: key + "%",
      data: rows.map((row) => ({
        x: row["datetime"],
        y: row.total_amount_usd,
      })),
    };

    series.push(item);
  });
  series.forEach((item) => {
    item.data[item.data.length - 1].x = now;
  });
  const options = {
    maintainAspectRatio: false,
    orderSeries: true,
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "x",
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return tooltipTitleDateTime(tooltipItems);
          },
          label: (tooltipItem) => {
            return tooltipLabelNumber(tooltipItem, "$");
          },
        },
      },
    },
  };

  return <Graph style={{ height: "33.33vh" }} series={series} options={options} />;
}

function MarketAtRiskHistoryChart(props) {
  const { type, symbol } = props;
  const { data, isLoading, isError } = useFetch(`at-risk/history/`, {
    type: type,
    symbol: symbol,
  });

  return (
    <LoadingCard isError={isError} isLoading={isLoading} data={data}>
      <GraphComponent data={data} />
    </LoadingCard>
  );
}

export default withErrorBoundary(MarketAtRiskHistoryChart);

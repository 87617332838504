import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import Card from "../../../components/Card/Card.js";
import Loader from "../../../components/Loader/Loader.js";
import React from "react";
import RevenueTable from "./RevenueTable.js";

function MakerProjection(props) {
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "treasury/revenue-projection/"
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }
  return (
    <>
      <Card title={"Maker revenue"}>
        <RevenueTable data={data} />
      </Card>
    </>
  );
}

export default withErrorBoundary(MakerProjection);

import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { uniqueId } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { UncontrolledTooltip } from "reactstrap";
function TitleWithTooltip(props) {
  const { title, tooltipValue, identifier } = props;

  let uniqueIdentifier;

  if (identifier) {
    uniqueIdentifier = identifier;
  } else {
    uniqueIdentifier = `tooltip-${uniqueId()}`;
  }

  return (
    <>
      {title}
      <FontAwesomeIcon
        icon={faCircleQuestion}
        className={classnames("link gray ms-2", { small: true })}
        id={uniqueIdentifier}
      />
      <UncontrolledTooltip
        placement="top"
        className="text-left"
        target={uniqueIdentifier}
      >
        {tooltipValue}
      </UncontrolledTooltip>
    </>
  );
}

TitleWithTooltip.propTypes = {
  title: PropTypes.string,
};

export default TitleWithTooltip;

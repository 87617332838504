import React from "react";
import { Badge, Col, Row } from "reactstrap";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Loader from "../../../components/Loader/Loader.js";
import LinkTable from "../../../components/Table/LinkTable.js";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch, useSmartNavigate } from "../../../hooks.js";
function Params(props) {
  const { daysAgo } = props;
  const navigate = useSmartNavigate();
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    "markets/params/",
    { days_ago: daysAgo }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const onRowClick = (e, row) => {
    navigate(e, `markets/${row.underlying_symbol}/`);
  };

  return (
    <>
      <Row className="mb-4">
        <Col>
          <LinkTable
            keyField="underlying_address"
            data={data.results}
            onRowClick={onRowClick}
            defaultSorted={[
              {
                dataField: "total_supply_usd",
                order: "desc",
              },
            ]}
            columns={[
              {
                dataField: "underlying_symbol",
                text: "",
                sort: false,
                formatter: (cell, row) => (
                  <CryptoIcon
                    className="me-2"
                    name={row.underlying_symbol}
                    size="2rem"
                  />
                ),
              },
              {
                dataField: "underlying_name",
                text: "",
                sort: false,
                formatter: (cell, row) => (
                  <>
                    {row.underlying_symbol}
                    <br />
                    <span className="gray text-small">{row.underlying_name}</span>
                  </>
                ),
              },
              {
                dataField: "ltv",
                text: "LTV",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "liquidation_threshold",
                text: "Liquidation Threshold",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "emode_category_name",
                text: "emode category",
                sort: false,
                formatter: (cell) => <>{cell ? cell : "-"}</>,
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "liquidation_bonus",
                text: "Liquidation Bonus",
                formatter: (cell, row) => (
                  <>
                    <Value
                      value={Number(cell) > 1 ? (cell - 1) * 100 : cell}
                      decimals={2}
                      suffix="%"
                      compact
                    />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "reserve_factor",
                text: "Reserve Factor",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "supply_cap",
                text: "Supply Cap",
                formatter: (cell, row) => (
                  <>
                    <Value
                      value={cell}
                      decimals={2}
                      suffix={row.underlying_symbol}
                      compact
                      dashIfZero
                    />
                    <br />
                    <Value
                      value={cell * row.underlying_price}
                      decimals={2}
                      prefix="$"
                      compact
                      className="gray"
                      small
                      hideIfZero
                    />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "borrow_cap",
                text: "Borrow Cap",
                formatter: (cell, row) => (
                  <>
                    <Value
                      value={cell}
                      decimals={2}
                      suffix={row.underlying_symbol}
                      compact
                      dashIfZero
                    />
                    <br />
                    <Value
                      value={cell * row.underlying_price}
                      decimals={2}
                      prefix="$"
                      compact
                      className="gray"
                      small
                      hideIfZero
                    />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "optimal_usage_ratio",
                text: "Optimal Utilization",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "isolated_debt_ceiling",
                text: "Isolated Debt Ceiling",
                formatter: (cell, row) => (
                  <Value value={cell} decimals={2} dashIfZero compact prefix={"$"} />
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "base_variable_borrow_rate",
                text: "Base Rate",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },

              {
                dataField: "variable_rate_slope_1",
                text: "Variable Slope 1",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "variable_rate_slope_2",
                text: "Variable Slope 2",
                formatter: (cell, row) => (
                  <>
                    <Value value={cell * 100} decimals={2} suffix="%" compact />
                  </>
                ),
                headerAlign: "right",
                align: "right",
              },
              {
                dataField: "total_supply_usd",
                text: "Total Supply (USD)",
                sort: true,
                hidden: true,
              },
              {
                dataField: "tags",
                text: "",
                sort: false,
                formatter: (cell, row) => {
                  const badges = cell.map((badge, index) => (
                    <Badge key={index} className="me-1">
                      {badge}
                    </Badge>
                  ));
                  const badgeList = badges.reduce((list, badge, index) => {
                    if (index === 2) {
                      list.push(<br />);
                    }
                    list.push(badge);
                    return list;
                  }, []);
                  return <>{badgeList}</>;
                },
                headerAlign: "right",
                align: "right",
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(Params);

import makeBlockie from "ethereum-blockies-base64";
import React, { useState } from "react";
import Address from "../../../components/Address/Address.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import Value from "../../../components/Value/Value.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch, useSmartNavigate } from "../../../hooks";
import { useShowQueryParams } from "../../../hooks";
import TimeSwitch from "../../../components/TimeSwitch/TimeSwitch.js";

function WalletPositions(props) {
  const { symbol } = props;
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-datetime");
  const navigate = useSmartNavigate();
  const { queryParams, setQueryParams } = useShowQueryParams();
  const timePeriod = queryParams.get("days_ago") || "1";
  const switchChange = (newValue) => setQueryParams({ days_ago: newValue });

  const onRowClick = (e, row) => {
    navigate(e, `wallets/${row.address}/`);
  };

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/wallets/`,
    {
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const options = [
    { key: "1", value: "1 D" },
    { key: "7", value: "7 D" },
    { key: "30", value: "30 D" },
  ];

  const columns = [
    {
      dataField: "",
      text: "",
      formatter: (_, row) => {
        const blockie = makeBlockie(row.address);
        return <img src={blockie} alt={row.address} />;
      },
      classes: "blockie",
    },
    {
      dataField: "address",
      text: "",
      formatter: (cell) => <Address value={cell} short />,
    },

    {
      dataField: "supply",
      text: "Supply",
      sort: true,
      formatter: (cell, row) => (
        <>
          <Value value={row.supply} decimals={2} suffix={symbol} compact icon />
          <br />
          <Value
            value={row.supply_usd}
            decimals={2}
            prefix="$"
            compact
            className="gray"
          />
        </>
      ),
      headerAlign: "right",
      align: "right",
    },

    {
      dataField: `supply_change_${timePeriod}d`,
      text: `supply change`,
      sort: true,
      formatter: (cell, row) => (
        <>
          {cell ? (
            <>
              <ValueChange value={cell} decimals={2} suffix={symbol} compact icon />
              <br />
              <ValueChange
                value={row[`supply_change_${timePeriod}d_usd`]}
                decimals={2}
                prefix="$"
                className="gray"
                compact
                small
              />
            </>
          ) : null}
        </>
      ),
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "borrow",
      text: "Borrow",
      sort: true,
      formatter: (cell, row) => (
        <>
          <Value value={row.borrow} decimals={2} suffix={symbol} compact icon />
          <br />
          <Value
            value={row.borrow_usd}
            decimals={2}
            prefix="$"
            compact
            className="gray"
          />
        </>
      ),
      headerAlign: "right",
      align: "right",
    },

    {
      dataField: `borrow_change_${timePeriod}d`,
      text: `borrow change`,
      sort: true,
      formatter: (cell, row) => (
        <>
          {cell ? (
            <>
              <ValueChange value={cell} decimals={2} suffix={symbol} compact icon />
              <br />
              <ValueChange
                value={row[`borrow_change_${timePeriod}d_usd`]}
                decimals={2}
                prefix="$"
                className="gray"
                compact
                small
              />
            </>
          ) : null}
        </>
      ),
      headerAlign: "right",
      align: "right",
    },
  ];

  return (
    <>
      <TimeSwitch
        activeOption={timePeriod}
        onChange={switchChange}
        options={options}
        className="justify-content-center mb-5"
      />

      {data.length === 0 ? (
        <div className="text-center gray">no wallets</div>
      ) : (
        <RemoteTable
          columns={columns}
          loading={isPreviousData}
          onRowClick={onRowClick}
          keyField="address"
          data={data.results}
          defaultSorted={[
            {
              dataField: "datetime",
              order: "desc",
            },
          ]}
          page={page}
          pageSize={pageSize}
          totalPageSize={data.count}
          onPageChange={setPage}
          onSortChange={setOrder}
        />
      )}
    </>
  );
}

export default withErrorBoundary(WalletPositions);

import React from "react";
import { useFetch } from "../../../hooks";
import LoadingCard from "../../../components/LoadingCard/LoadingCard";
import Graph from "../../../components/Graph/Graph.js";
import { SYMBOLS_PALETTE } from "../../../utils/colors.js";
import { pieGraphDataLimiter, pieTooltipLabelNumber } from "../../../utils/graph.js";
import Loader from "../../../components/Loader/Loader.js";
import TitleWithTooltip from "../../../components/TitleWithTooltip/TitleWithTooltip.js";

function DoughnutData({ data }) {
  const { series, labels } = pieGraphDataLimiter(data, "symbol", "amount", 2);

  const backgroundColors = labels.map((label) => SYMBOLS_PALETTE[label] || "#D2691E");
  const borderColors = labels.map((label) => SYMBOLS_PALETTE[label] || "#D2691E");

  const newSeries = [
    {
      data: series[0]["data"],
      backgroundColor: backgroundColors,
      borderColor: borderColors,
    },
  ];

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return pieTooltipLabelNumber(context, "$", "");
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    cutout: "42%",
    layout: {
      padding: 0,
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  return <Graph type="pie" series={newSeries} labels={labels} options={options} />;
}

function AssetAsCollateralComposition(props) {
  const { symbol } = props;
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `markets/${symbol}/collateral-composition/`
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  } else if (data.length === 0) {
    return <> </>;
  }
  const title = (
    <TitleWithTooltip
      title="Liability collateralization"
      tooltipValue={`Amount of ${symbol} collateralizing underyling liabilities.`}
    />
  );
  return (
    <LoadingCard title={title} isError={isError} isLoading={isLoading} data={data}>
      <DoughnutData data={data} />
    </LoadingCard>
  );
}

export default AssetAsCollateralComposition;

import React from "react";
import { Badge, Col, Row } from "reactstrap";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import Loader from "../../../components/Loader/Loader.js";
import Value from "../../../components/Value/Value.js";
import ValueChange from "../../../components/Value/ValueChange.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { useState } from "react";
import Table from "../../../components/Table/Table.js";
import { faBank } from "@fortawesome/free-solid-svg-icons";
import InlineSelect from "../../../components/Select/InlineSelect.js";
import { sortArray } from "../../../utils/array.js";
import { smartLocationPrefix } from "../../../utils/url.js";
import { useLocation } from "react-router-dom";

function MarketsTable(props, ...rest) {
  const location = useLocation();
  const prefix = smartLocationPrefix(location);
  const { daysAgo } = props;
  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch("markets/", {
    days_ago: daysAgo,
  });
  const [isPriceUsd, setIsPriceUsd] = useState(true);
  const [order, setOrder] = useState(
    isPriceUsd ? "-total_supply_usd" : "-total_supply"
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const columns = [
    {
      header: "#",
      cell: ({ index }) => (
        <span className="font-syncopate text-gray-7">{index + 1}</span>
      ),
      headerAlign: "start",
      cellAlign: "start",
      cellSize: "0.2fr",
    },
    {
      header: "Asset",
      cell: ({ row }) => <CryptoIcon className="me-2" name={row.symbol} size="2rem" />,

      cellAlign: "center",
      headerAlign: "center",
      cellSize: "0.2fr",
    },
    {
      header: "",
      cell: ({ row }) => (
        <>
          {row.symbol}
          <span className="text-small gray ms-2">
            (
            <Value value={row.underlying_price} decimals={2} prefix="$" />)
          </span>
          <div className="text-small gray">{row.underlying_name}</div>
        </>
      ),
      headerAlign: "start",
      cellAlign: "start",
      cellSize: "1.5fr",
      visibleAfter: "xxl",
    },
    {
      header: "Supplied",
      cell: ({ row }) => (
        <>
          {isPriceUsd ? (
            <Value value={row.total_supply_usd} decimals={2} prefix={"$"} compact />
          ) : (
            <Value value={row.total_supply} decimals={2} suffix={row.symbol} compact />
          )}
        </>
      ),
      smallCell: ({ row }) => (
        <>
          {isPriceUsd ? (
            <ValueChange
              value={row.total_supply_usd - row.change.total_supply_usd}
              decimals={2}
              prefix={"$"}
              compact
              hideIfZero
            />
          ) : (
            <ValueChange
              value={row.total_supply - row.change.total_supply}
              decimals={2}
              suffix={row.symbol}
              compact
              hideIfZero
            />
          )}
        </>
      ),
      headerAlign: "end",
      cellAlign: "end",
      orderField: isPriceUsd ? "total_supply_usd" : "total_supply",
    },

    {
      header: "Borrowed",
      cell: ({ row }) => (
        <>
          {isPriceUsd ? (
            <Value value={row.total_borrow_usd} decimals={2} prefix={"$"} compact />
          ) : (
            <Value value={row.total_borrow} decimals={2} suffix={row.symbol} compact />
          )}
        </>
      ),
      smallCell: ({ row }) => (
        <>
          {isPriceUsd ? (
            <ValueChange
              value={row.total_borrow_usd - row.change.total_borrow_usd}
              decimals={2}
              prefix={"$"}
              compact
              hideIfZero
            />
          ) : (
            <ValueChange
              value={row.total_borrow - row.change.total_borrow}
              decimals={2}
              suffix={row.symbol}
              compact
              hideIfZero
            />
          )}
        </>
      ),
      headerAlign: "end",
      cellAlign: "end",
      orderField: isPriceUsd ? "total_borrow_usd" : "total_borrow",
    },

    {
      header: "Deposit APY",
      cell: ({ row }) => (
        <Value value={row.supply_apy * 100} decimals={2} suffix="%" compact />
      ),
      smallCell: ({ row }) => (
        <ValueChange
          value={(row.supply_apy - row.change.supply_apy) * 100}
          decimals={2}
          suffix="%"
          compact
          hideIfZero
        />
      ),
      headerAlign: "end",
      cellAlign: "end",
      orderField: "supply_apy",
      visibleAfter: "sm",
    },
    {
      header: "Borrow APY",
      cell: ({ row }) => (
        <Value value={row.borrow_variable_apy * 100} decimals={2} suffix="%" compact />
      ),
      smallCell: ({ row }) => (
        <ValueChange
          value={(row.borrow_variable_apy - row.change.borrow_variable_apy) * 100}
          decimals={2}
          suffix="%"
          compact
          hideIfZero
        />
      ),
      headerAlign: "end",
      cellAlign: "end",
      orderField: "borrow_variable_apy",
      visibleAfter: "md",
    },
    {
      header: "Utilization",
      cell: ({ row }) => (
        <Value value={row.utilization_rate * 100} decimals={2} suffix="%" compact />
      ),
      smallCell: ({ row }) => (
        <ValueChange
          value={(row.utilization_rate - row.change.utilization_rate) * 100}
          decimals={2}
          suffix="%"
          compact
          hideIfZero
        />
      ),
      headerAlign: "end",
      cellAlign: "end",
      orderField: "utilization_rate",
      visibleAfter: "lg",
    },
    {
      header: "",
      cell: ({ row }) => {
        const badges = row.tags.map((badge, index) => (
          <Badge className="me-1" key={index}>
            {badge}
          </Badge>
        ));
        return <>{badges}</>;
      },
      cellAlign: "start",
      cellSize: "1.5fr",
      headerAlign: "start",
      visibleAfter: "xl",
    },
  ];

  const priceOptions = [
    { key: "token", value: "Token" },
    { key: "usd", value: "USD" },
  ];

  const onPriceOptionChange = (event) => {
    setIsPriceUsd(event.target.value === "usd");
  };

  const footerRow = (
    <div className="text-sm gray text-end flex-container">
      Prices shown in{" "}
      <InlineSelect
        options={priceOptions}
        value={isPriceUsd ? "usd" : "token"}
        onChange={onPriceOptionChange}
      />
    </div>
  );

  const sortedData = sortArray(data, order);

  return (
    <Row className="mb-4">
      <Col>
        <Table
          data={sortedData}
          keyField="symbol"
          columns={columns}
          isLoading={isLoading}
          footerRow={footerRow}
          emptyIcon={faBank}
          href={(row) => `${prefix}markets/${row.symbol}/`}
          emptyTitle="No markets"
          currentOrder={order}
          onOrderChange={setOrder}
        />
      </Col>
    </Row>
  );
}

export default withErrorBoundary(MarketsTable);

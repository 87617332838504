import React from "react";
import { withErrorBoundary } from "../../../hoc.js";
import { parseUTCDateTime } from "../../../utils/datetime.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import StatsBar from "../../../components/Stats/StatsBar.js";
import ValueChange from "../../../components/Value/ValueChange.js";

function WalletAdditionalInfo(props) {
  let { data, ...rest } = props;
  if (!data) {
    return null;
  }

  const {
    last_activity: lastActivity,
    first_activity: firstActivity,
    liquidation_buffer: liquidationBuffer,
    account_liquidity: accountLiquidity,
  } = data || {};

  const additionalStats = [
    {
      title: "liquidation buffer",
      normalValue: (
        <>
          <ValueChange
            value={liquidationBuffer}
            dashIfZero
            decimals={2}
            prefix={"$"}
            compact
          />
        </>
      ),
    },
    {
      title: "account liquidity",
      normalValue: (
        <>
          <ValueChange
            value={accountLiquidity}
            dashIfZero
            decimals={2}
            prefix={"$"}
            compact
          />
        </>
      ),
    },

    {
      title: "last activity",
      normalValue: <DateTimeAgo dateTime={parseUTCDateTime(lastActivity)} inDays />,
    },
    {
      title: "first activity",
      normalValue: <DateTimeAgo dateTime={parseUTCDateTime(firstActivity)} inDays />,
    },
  ];

  return <StatsBar stats={additionalStats} {...rest} />;
}

export default withErrorBoundary(WalletAdditionalInfo);

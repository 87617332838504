import { withErrorBoundary } from "../../../hoc.js";
import Card from "../../../components/Card/Card.js";
import React from "react";
import Table from "../../../components/Table/Table.js";
import Value from "../../../components/Value/Value.js";
import { ReactComponent as AAVELogo } from "../../../images/aave-logo.svg";

function getQuarterStart(year, quarter) {
  let q = quarter - 1;
  let quarterStart = new Date(year, q * 3, 1);
  return quarterStart.toDateString().split(" ").slice(1, 3).join(" ");
}

function getQuarterEnd(year, quarter) {
  let quarterEnd = new Date(year, quarter * 3, 0);
  return quarterEnd.toDateString().split(" ").slice(1, 3).join(" ");
}

function AavePaymentsTable({ data }) {
  const columns = [
    {
      header: "quarter",
      cell: ({ row }) => (
        <>
          {"Q" + row.quarter}{" "}
          <span className="gray small">
            ({getQuarterStart(row.year, row.quarter)} -{" "}
            {getQuarterEnd(row.year, row.quarter)}, {row.year})
          </span>
        </>
      ),
      headerAlign: "start",
      cellAlign: "start",
    },
    {
      header: "Beneficiary",
      cell: () => (
        <>
          <AAVELogo height={20} />
        </>
      ),
      cellAlign: "center",
      headerAlign: "center",
    },
    {
      header: "revenue",
      cell: ({ row }) => (
        <>
          <Value value={row.payout} decimals={2} prefix={"$"} compact />
        </>
      ),
      headerAlign: "end",
      cellAlign: "end",
    },
  ];
  return (
    <>
      <Card title="Aave quarterly payments" data={data}>
        <Table data={data} keyField="symbol" columns={columns} />
      </Card>
    </>
  );
}

export default withErrorBoundary(AavePaymentsTable);

import React from "react";
import Graph from "../../../components/Graph/Graph.js";
import LoadingCard from "../../../components/LoadingCard/LoadingCard.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks";
import { SYMBOLS_PALETTE } from "../../../utils/colors.js";
import {
  barGraphSeriesCountLimiter,
  tooltipLabelNumberWithPercent,
} from "../../../utils/graph.js";
import { compact } from "../../../utils/number.js";

function AggregatedAtRiskChart(props) {
  const { price_change, type } = props;
  const { data, isLoading, isError } = useFetch(
    "at-risk/assets/",
    {
      price_change,
      type,
    },
    { keepPreviousData: true }
  );

  let title;
  if (type === "collateral") {
    title = "Collateral at risk";
  } else {
    title = "Repayable debt";
  }

  const { series } = barGraphSeriesCountLimiter(data, "symbol", "amount_usd", 10);
  const backgroundColors = series[0]["data"].map((row) => {
    return SYMBOLS_PALETTE[row["x"]] || "#0e1726";
  });

  const borderColors = series[0]["data"].map((row) => {
    return SYMBOLS_PALETTE[row["x"]] || "#0e1726";
  });

  const newSeries = [
    {
      data: series[0]["data"],
      backgroundColor: backgroundColors,
      borderColor: borderColors,
    },
  ];

  const options = {
    scales: {
      y: {
        ticks: {
          callback: (value) => "$" + compact(value, 2, true),
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: (tooltipItem) => {
          return tooltipItem.parsed.y !== 0 && tooltipItem.parsed.y !== null;
        },
        callbacks: {
          label: (tooltipItem) => {
            return tooltipLabelNumberWithPercent(tooltipItem, "$");
          },
        },
      },
    },
  };

  return (
    <LoadingCard title={title} isLoading={isLoading} isError={isError} data={data}>
      <Graph series={newSeries} type="bar" options={options} />
    </LoadingCard>
  );
}

export default withErrorBoundary(AggregatedAtRiskChart);

import React, { useState } from "react";
import Address from "../../../components/Address/Address.js";
import CryptoIcon from "../../../components/CryptoIcon/CryptoIcon.js";
import DateTimeAgo from "../../../components/DateTime/DateTimeAgo.js";
import ExternalLink from "../../../components/ExternalLink/ExternalLink.js";
import Loader from "../../../components/Loader/Loader.js";
import RemoteTable from "../../../components/Table/RemoteTable.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import { parseUTCDateTime } from "../../../utils/datetime.js";
import { useLocation } from "react-router-dom";
import { smartEtherscanUrl } from "../../../utils/url.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Value from "../../../components/Value/Value.js";
import favicon from "../../../images/favicon.png";

function PoolConfigurationEventsTable(props) {
  const pageSize = 5;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("-datetime");
  const location = useLocation();
  const etherscanUrl = smartEtherscanUrl(location);

  const { data, isLoading, isPreviousData, isError, ErrorFallbackComponent } = useFetch(
    `markets/params-events/`,
    {
      p: page,
      p_size: pageSize,
      order,
    },
    { keepPreviousData: true }
  );
  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  const { results } = data;
  /* so the Events all start with a capitalized letter */
  function splitCaps(text) {
    if (typeof text !== "string") {
      return text;
    }
    return text.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
  /* Formatters for events */
  const formatEventReserveInitialzed = (cell) => {
    return (
      <div>
        <div className="me-1">
          aToken Address : <Address value={cell.aToken} short />
          <span className="gray text-xsmall">
            <ExternalLink
              href={`${etherscanUrl}address/${cell.aToken}`}
              className="ms-1 gray"
            />
          </span>
        </div>
        <div className="me-1">
          Stable Debt Token Address : <Address value={cell.stableDebtToken} short />
          <span className="gray text-xsmall">
            <ExternalLink
              href={`${etherscanUrl}address/${cell.stableDebtToken}`}
              className="ms-1 gray"
            />
          </span>
        </div>
        <div className="me-1">
          Variable Debt Token Addres : <Address value={cell.variableDebtToken} short />
          <span className="gray text-xsmall">
            <ExternalLink
              href={`${etherscanUrl}address/${cell.variableDebtToken}`}
              className="ms-1 gray"
            />
          </span>
        </div>
        <div className="me-1">
          Interest Rate Strategy Addres :{" "}
          <Address value={cell.interestRateStrategyAddress} short />
          <span className="gray text-xsmall">
            <ExternalLink
              href={`${etherscanUrl}address/${cell.interestRateStrategyAddress}`}
              className="ms-1 gray"
            />
          </span>
        </div>
      </div>
    );
  };

  const formatEventCollateralConfigurationChanged = (cell) => {
    return (
      <div>
        LTV: <Value value={cell.ltv / 100} decimals={2} suffix="%" compact />
        <br />
        Liquidation Bonus:{" "}
        <Value
          value={cell.liquidationBonus / 100 - 100}
          suffix="%"
          decimals={2}
          compact
        />
        <br />
        Liquidation Threshold:{" "}
        <Value
          value={cell.liquidationThreshold / 100}
          suffix="%"
          decimals={2}
          compact
        />
      </div>
    );
  };

  const formatEventEModeCategoryAdded = (cell) => {
    return (
      <div>
        LTV: <Value value={cell.ltv / 100} decimals={2} suffix="%" compact />
        <br />
        Label: {cell.label}
        <br />
        Liquidation Bonus:{" "}
        <Value
          value={cell.liquidationBonus / 100 - 100}
          suffix="%"
          decimals={2}
          compact
        />
        <br />
        Liquidation Threshold:{" "}
        <Value
          value={cell.liquidationThreshold / 100}
          suffix="%"
          decimals={2}
          compact
        />
      </div>
    );
  };

  const formatEventStrategyOldNew = (cell) => {
    return (
      <div className="me-1">
        New Strategy Address : <Address value={cell.newStrategy} short />
        <span className="gray text-xsmall">
          <ExternalLink
            href={`${etherscanUrl}address/${cell.newStrategy}`}
            className="ms-1 gray"
          />
        </span>
      </div>
    );
  };

  const formatGenericEventsOldNew = (
    cell,
    newKey,
    oldKey,
    format = false,
    percentage = false
  ) => {
    const title = `New ${splitCaps(newKey.replace("new", ""))} :`;

    const formatValue = (key) => {
      if (isBoolean(cell[key])) return String(cell[key]);
      return percentage ? cell[key] / 100 : cell[key];
    };

    const isBoolean = (value) => typeof value === "boolean";

    const ValueComponent = (key) => (
      <Value
        value={formatValue(key)}
        decimals={2}
        suffix={format ? "%" : null}
        compact
      />
    );

    return (
      <div className="me-1">
        {title}{" "}
        {isBoolean(cell[oldKey]) ? String(cell[oldKey]) : ValueComponent(oldKey)}
        <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
        {isBoolean(cell[newKey]) ? String(cell[newKey]) : ValueComponent(newKey)}
      </div>
    );
  };
  const formatGenericBooleanEvents = (cell) => {
    const values = Object.values(cell);
    return <div className="me-1">{String(values[1])}</div>;
  };
  /* Mapping from events to formatters*/
  const eventFormattersMap = {
    /* for Reserve Initialized Event only */
    ReserveInitialized: (cell) => formatEventReserveInitialzed(cell),

    /* for Collateral Configuration Changed Event only */
    CollateralConfigurationChanged: (cell) =>
      formatEventCollateralConfigurationChanged(cell),

    /* for EMode Category Added Event only */
    EModeCategoryAdded: (cell) => formatEventEModeCategoryAdded(cell),

    /* for Reserve Interest Rate Strategy Changed Event only */
    ReserveInterestRateStrategyChanged: (cell) => formatEventStrategyOldNew(cell),

    /* for One line boolean Events generic */
    ReserveFrozen: (cell) => formatGenericBooleanEvents(cell),
    ReserveActive: (cell) => formatGenericBooleanEvents(cell),
    ReservePaused: (cell) => formatGenericBooleanEvents(cell),
    "ReservedFlash Loaning": (cell) => formatGenericBooleanEvents(cell),
    BorrowableInIsolationChanged: (cell) => formatGenericBooleanEvents(cell),
    ReserveStableRateBorrowing: (cell) => formatGenericBooleanEvents(cell),
    ReserveBorrowing: (cell) => formatGenericBooleanEvents(cell),
    ReserveFlashLoaning: (cell) => formatGenericBooleanEvents(cell),

    /* for New/Old Events with 2 parameters generic */
    SupplyCapChanged: (cell) =>
      formatGenericEventsOldNew(cell, "newSupplyCap", "oldSupplyCap"),
    ReserveFactorChanged: (cell) =>
      formatGenericEventsOldNew(
        cell,
        "newReserveFactor",
        "oldReserveFactor",
        true,
        true
      ),
    NewFlashloanPremiumTotal: (cell) =>
      formatGenericEventsOldNew(
        cell,
        "newFlashloanPremiumTotal",
        "oldFlashloanPremiumTotal",
        true
      ),
    NewState: (cell) => formatGenericEventsOldNew(cell, "newState", "oldState"),
    BorrowCapChanged: (cell) =>
      formatGenericEventsOldNew(cell, "newBorrowCap", "oldBorrowCap"),
    DebtCeilingChanged: (cell) =>
      formatGenericEventsOldNew(cell, "newDebtCeiling", "oldDebtCeiling"),
    EModeAssetCategoryChanged: (cell) =>
      formatGenericEventsOldNew(cell, "newCategoryId", "oldCategoryId"),
    UnbackedMintCapChanged: (cell) =>
      formatGenericEventsOldNew(cell, "newUnbackedMintCap", "oldUnbackedMintCap"),
    FlashloanPremiumTotalUpdated: (cell) =>
      formatGenericEventsOldNew(
        cell,
        "newFlashloanPremiumTotal",
        "oldFlashloanPremiumTotal",
        true
      ),
    NewFlashloanPremiumToProtocolUpdated: (cell) =>
      formatGenericEventsOldNew(
        cell,
        "newFlashloanPremiumToProtocol",
        "oldFlashloanPremiumToProtocol",
        true
      ),
    SiloedBorrowingChanged: (cell) =>
      formatGenericEventsOldNew(cell, "newState", "oldState"),
    LiquidationProtocolFeeChanged: (cell) =>
      formatGenericEventsOldNew(cell, "newFee", "oldFee", true, true),
    BridgeProtocolFeeUpdated: (cell) =>
      formatGenericEventsOldNew(cell, "newBridgeProtocolFee", "oldBridgeProtocolFee"),
  };

  return (
    <RemoteTable
      loading={isPreviousData}
      hover={false}
      keyField="order_index"
      data={results}
      defaultSorted={[
        {
          dataField: "datetime",
          order: "desc",
        },
      ]}
      columns={[
        {
          dataField: "underlying_symbol",
          text: "",
          sort: false,
          formatter: (cell, row) =>
            row.underlying_symbol ? (
              <CryptoIcon className="me-2" name={cell} size="2rem" />
            ) : (
              <img
                src={favicon}
                alt="Spark Logo"
                style={{ width: "2rem", height: "2rem" }}
                className="me-2"
              />
            ),
        },

        {
          dataField: "underlying_symbol",
          text: "",
          sort: false,
          formatter: (cell, row) => (
            <>
              {cell ? cell : "Spark"}
              <br />
              <span className="gray text-small">
                {row.underlying_name ? row.underlying_name : "Spark Protocol"}
              </span>
            </>
          ),
        },
        {
          dataField: "event",
          text: "Event",
          sort: false,
          headerAlign: "left",
          align: "left",
        },

        {
          dataField: "args",
          text: "Parameter Changes",
          sort: false,
          formatter: (cell, row) => {
            if (eventFormattersMap[row.event]) {
              return eventFormattersMap[row.event](cell, row);
            }

            return (
              <>
                {Object.entries(cell).map(([key, value], index) => (
                  <div key={index} className="me-1">
                    {splitCaps(key)}: {value}
                  </div>
                ))}
              </>
            );
          },
          headerAlign: "left",
          align: "left",
        },

        {
          dataField: "block_number",
          text: "Date",
          sort: true,
          formatter: (cell, row) => (
            <>
              <DateTimeAgo dateTime={parseUTCDateTime(row.datetime)} />
              <br />
              <span className="gray text-small">
                {cell}
                <ExternalLink
                  href={`${etherscanUrl}address/${row.tx_hash}`}
                  className="ms-2 gray"
                />
              </span>
            </>
          ),
          headerAlign: "right",
          align: "right",
        },
      ]}
      page={page}
      pageSize={pageSize}
      totalPageSize={data.count}
      onPageChange={setPage}
      onSortChange={setOrder}
    />
  );
}

export default withErrorBoundary(PoolConfigurationEventsTable);

import React from "react";
import { Col, Row } from "reactstrap";
import Loader from "../../../components/Loader/Loader.js";
import { withErrorBoundary } from "../../../hoc.js";
import { useFetch } from "../../../hooks.js";
import WalletPositionsBorrow from "./WalletPositionsBorrow.js";
import WalletPositionsSupply from "./WalletPositionsSupply.js";

function WalletPositions(props) {
  const { address } = props;

  const { data, isLoading, isError, ErrorFallbackComponent } = useFetch(
    `wallets/${address}/positions/`
  );

  if (isLoading) {
    return <Loader />;
  } else if (isError) {
    return <ErrorFallbackComponent />;
  }

  let supply = [];
  let borrow = [];
  function sortDescending(a, b) {
    return b.supply_usd - a.supply_usd;
  }
  data.forEach((item) => {
    if (item.supply !== 0) {
      supply.push(item);
    }
    if (item.borrow !== 0) {
      borrow.push(item);
    }
  });
  supply.sort(sortDescending);
  borrow.sort(sortDescending);
  return (
    <>
      <Row className="mb-5">
        <Col md={6}>
          <WalletPositionsSupply data={supply} />
        </Col>
        <Col md={6}>
          <WalletPositionsBorrow data={borrow} />
        </Col>
      </Row>
    </>
  );
}

export default withErrorBoundary(WalletPositions);

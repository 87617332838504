import { faInfinity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React from "react";
import Value from "../../../components/Value/Value.js";
import { withErrorBoundary } from "../../../hoc.js";
import styles from "./WalletInfo.module.scss";

function WalletInfo(props) {
  let { data, ...rest } = props;
  if (!data) {
    data = {};
  }

  const { health_rate: healthRate } = data;

  return (
    <div {...rest}>
      <div className={styles.sectionWrapper}>
        <div className={styles.section}>
          <div className="section-title">supplied</div>
          <div>
            {
              <Value
                value={data.supply ? data.supply : 0}
                decimals={2}
                prefix={"$"}
                iconSize="1.2rem"
                compact
              />
            }
          </div>
        </div>

        <div className={classnames(styles.section, styles.sectionMiddle)}>
          <div className="section-title">Health Rate</div>
          <div className={styles.sectionMiddleValue}>
            {healthRate ? (
              <>
                <Value value={healthRate} decimals={3} compact />
                <div style={{ fontSize: "14px", color: "#777" }}>
                  <Value
                    value={data.liquidation_price_change}
                    decimals={2}
                    suffix="%"
                    hideIfZero
                  />
                </div>
              </>
            ) : (
              <FontAwesomeIcon icon={faInfinity} />
            )}
          </div>
        </div>

        <div className={styles.section}>
          <div className="section-title">borrowed</div>
          <div>
            <Value
              value={data.borrow ? data.borrow : 0}
              decimals={2}
              prefix={"$"}
              iconSize="1.2rem"
              compact
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withErrorBoundary(WalletInfo);

import { useState } from "react";
import Select from "../../../components/Select/Select.js";
import Value from "../../../components/Value/Value.js";
import { InputGroup, Button, Input, InputGroupText, Table } from "reactstrap";
import { withErrorBoundary } from "../../../hoc.js";

function AssetForm({ assets, defaults, onSubmit }) {
  const [assetValues, setAssetValues] = useState(defaults || []);
  const selectOptions = assets.map((asset) => ({
    value: asset.underlying_price,
    label: asset.underlying_symbol,
  }));

  const onValueChange = (key, value) => {
    const updatedValues = assetValues.map((item) => {
      if (item.key === key) {
        return { ...item, value: parseFloat(value) || "" };
      }
      return item;
    });

    setAssetValues(updatedValues);
  };

  const onAddRow = () => {
    const availableAssets = assets.filter(
      (asset) => !assetValues.some((item) => item.key === asset.underlying_symbol)
    );
    const newRow = {
      key: availableAssets[0].underlying_symbol,
      value: 0,
      price: availableAssets[0].underlying_price,
    };
    setAssetValues([...assetValues, newRow]);
  };

  const onAssetChange = (key, option) => {
    const existingIndex = assetValues.findIndex((item) => item.key === option.label);
    if (existingIndex !== -1) {
      const updatedValues = assetValues.filter(
        (item, index) => index !== existingIndex
      );
      setAssetValues(updatedValues);
    } else {
      const updatedValues = [...assetValues];
      for (let i = 0; i < updatedValues.length; i++) {
        if (updatedValues[i].key === key) {
          updatedValues[i] = {
            ...updatedValues[i],
            key: option.label,
            price: option.value,
          };
          setAssetValues(updatedValues);
          break;
        }
      }
    }
  };
  const onRemoveRow = (key) => {
    const updatedValues = assetValues.filter((item) => item.key !== key);
    setAssetValues(updatedValues);
  };
  return (
    <div>
      <Table className="rounded">
        <thead>
          <tr>
            <th>Asset:</th>
            <th>Change:</th>
            <th>Price:</th>
            <th>Changed Price:</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {assetValues.map((item, index) => {
            const selectedOption = selectOptions.find(
              (option) => option.label === item.key
            );

            return (
              <tr key={item.key}>
                <td>
                  <Select
                    options={selectOptions}
                    defaultValue={selectedOption}
                    onChange={(options) => onAssetChange(item.key, options)}
                  />
                </td>
                <td>
                  <InputGroup>
                    <Input
                      type="number"
                      value={item.value}
                      min={-100}
                      max={100}
                      onChange={(e) => {
                        let val;
                        if (e.target.value < -99) {
                          val = -99;
                        } else if (e.target.value > 100) {
                          val = 100;
                        } else {
                          val = e.target.value;
                        }
                        onValueChange(item.key, val);
                      }}
                    />
                    <InputGroupText>%</InputGroupText>
                  </InputGroup>
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Value value={item.price} decimals={2} prefix="$" />
                </td>
                <td style={{ verticalAlign: "middle" }}>
                  <Value
                    value={item.price * (1 + item.value / 100)}
                    decimals={2}
                    prefix="$"
                  />
                </td>
                <td style={{ textAlign: "right", paddingRight: 0 }} className="">
                  <Button color="primary" onClick={() => onRemoveRow(item.key)}>
                    Remove
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-right d-flex justify-content-between">
        <Button color="primary" onClick={() => onSubmit(assetValues)}>
          Apply
        </Button>
        <Button color="primary" onClick={() => onAddRow()}>
          Add Row
        </Button>
      </div>
    </div>
  );
}

export default withErrorBoundary(AssetForm);

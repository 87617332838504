import React from "react";
import Card from "../Card/Card";
import Loader from "../Loader/Loader";
import ErrorFallback from "../errorFallback/ErrorFallback.js";

function LoadingCard({ isLoading, isError, data, children, ...props }) {
  let content = null;
  if (isLoading) {
    content = <Loader />;
  } else if (isError) {
    content = <ErrorFallback />;
  } else if (!data) {
    content = <div>No data</div>;
  }

  return (
    <Card className="mb-4" minHeight {...props}>
      {content ? (
        <div
          className="justify-content-center align-items-center d-flex"
          style={{ minHeight: "320px" }}
        >
          {content}
        </div>
      ) : (
        children
      )}
    </Card>
  );
}

export default LoadingCard;
